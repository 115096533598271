import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import '../../App.css';

function Supplies() {
  return (
    <>
      <Container fluid className="bgyellow rounded">
        <Row>
          <Col lg={6}>
            <h5 className="text-yellow mx-3 mt-5">
              Enhancing The Performance Of Hydroponic Growing
            </h5>
            <h2 className="text-light mx-3 " >
              Hydroponic Supplies for Latest Projects And Solutions{" "}
            </h2>
          </Col>

          <Col lg={6} className="mb-5">
            <p className="text-light mt-5 pt-4 px-2">
              Our team of expert is successfully catering to the needs of
              various clients, and has completed projects on more than 450 acres
              of landscape.
            </p>
            <Link to="/about" className="btn btn-success">
              <i class="fa-solid fa-circle-arrow-right fa-beat"> </i> Our Core
              Values
            </Link>
            {/* <Button variant="outline-success m-3"><i class="fa-solid fa-circle-arrow-right fa-beat"></i> Selling Support</Button> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Supplies;
