import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import PPT from "../../Assets/pp.pdf";
import Trailor from "../../Assets/hydroponic video/Trailor.mp4";
function AdvanceHydroponicFarmingTechnology() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              LEARN HYDROPONICS
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                LEARN HYDROPONICS
              </a>
            </div>
          </Col>
        </Row>
        <Row className="p-3">
          <Col className="p-3" lg={8}>
            <h3>What Is Hydroponics?</h3>
            <p>
              Hydroponics is a form of horticulture and a subset of hydroculture
              that involves using mineral nutrient solutions in an aqueous
              solvent to grow plants without soil.A hydroponic plant’s growth
              rate is 30-50 percent higher than soil-grown plants. Nutrients are
              more readily accessible for the plant to consume in hydroponics.
              Light, heat, nutrients, hydration, pests, and all other aspects of
              the growing process can be controlled by the grower.
            </p>
            <p>
              Plant growth is faster than with traditional methods, and the
              plants can be harvested in a shorter amount of time. With this
              soilless farming approach, the soil-borne disease is greatly
              avoided, and you receive healthy and nutritious crop products.
            </p>
            <h3>Advanced Hydroponics Training from Royal Green House</h3>
            <p>
              Royal Green House has assisted gardeners and farmers through
              training programmes.This course will teach you about modern
              technology and how it can be used to grow plants, with a focus on
              hydroponics and aquaponics. You’ll be able to start your own
              business with the skills you’ll learn throughout the course.Get
              100% practical knowledge & free site visit.
            </p>
            <p></p>
            <p>
              <b>Requirements : </b>
              <span>
                There is no need for prior experience, all that is required is
                an interest in gardening.
              </span>
            </p>
            <p>
              <b>Mode : </b>
              <span>Offline</span>
            </p>
          </Col>
          <Col className="p-3" lg={4}>
            <div className="text-center">
              {/* <iframe
                                    src={Trailor}
                                    height="250"
                                    title="Vimeo video"
                                    allowFullScreen
                                    className='rounded-4'
                                ></iframe> */}
              <video width="320" height="240" controls>
                <source src={Trailor} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="p-3">
            <iframe
              src={PPT}
              className="pptSize"
              frameborder="0"
              title="Iframe Example"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AdvanceHydroponicFarmingTechnology;
