import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";

import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/gallery/product1.jpg";
import CardImg2 from "../../Assets/gallery/product2.jpg";
import CardImg3 from "../../Assets/gallery/product4.jpg";
import CardImg4 from "../../Assets/gallery/product3.jpg";
import CardImg5 from "../../Assets/gallery/product5.jpg";

import Rose from "../../Assets/rose.png";
import CarnationsImg from "../../Assets/CarnationsImg.png";
import OrchidImg from "../../Assets/OrchidImg.png";
import AnthuriumImg from "../../Assets/AnthuriumImg.png";
import StrelitziaImg from "../../Assets/StrelitziaImg.png";
import Gerbera from "../../Assets/gerbera.png";
function Floriculture() {
  return (
    <Container fluid>
      <Row className="headingimg">
        <Col className="m-5">
          <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
            {" "}
            Products
          </h1>
          <div className="text-white m-4 fw-bold text-center">
            <a href="#" className=" text-white m-3 text-decoration-none">
              Home
            </a>
            <i className="fa-solid fa-circle-arrow-right fa-beat"></i>
            <a className="text-white text-decoration-none m-3">Products</a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="mt-4 pt-4 ">
          <h2 className="text-green m-3 text-center fw-bold">
            Our Fresh Produce
          </h2>
          <h6 className="text-center m-4 fw-bold">
            All our produce is fresh, nutritious and hand-picked. <br /> We are
            dedicated to deliver to you the top-shelf produce.
          </h6>
        </Col>
      </Row>

      <Row className="p-2">
        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg1} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Vine Crops
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg2} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Lettuces
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg3} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Leafy Greens
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="row justify-content-center">
        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg4} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Fresh Herbs
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg5} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Floriculture
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col sm={12} className="mt-4 pt-4 ">
          <h1 className="text-green m-3 text-center fw-bold">Floriculture</h1>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Gerbera
              </h2>
              <p className="text-justify ">
                Gerbera is a vibrant and cheerful flowering plant often used in
                bouquets and floral arrangements.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Appearance:</span>
                    <li>Large, vibrant, daisy-like blooms.</li>
                    <li>Wide range of bright colors.</li>
                    <li>Prominent central disc with petals.</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Uses:</span>
                    <li>Popular in floral arrangements and bouquets</li>
                    <li>Brightens up indoor and outdoor spaces</li>
                    <li>Adds color to gardens and decor</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={Gerbera} alt="img" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Rose
              </h2>
              <p className="text-center ">
                Rose, often used in beauty products and fragrances.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Uses:</span>
                    <li>Utilized in perfumes and fragrances</li>
                    <li>Incorporated into skincare and cosmetic products</li>
                    <li>Used in culinary applications for rosewater</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold">Appearance:</span>
                    <li>Large, vibrant petals in various colors</li>
                    <li>Distinctive fragrance with a soft texture</li>
                    <li>Often presented in elegant, multi-layered blooms</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={Rose} alt="img" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Carnations
              </h2>
              <p className="text-center ">
                Carnations, known for their vibrant colors and spicy scent, are
                popular in floral arrangements and perfumes, and are valued for
                their soothing properties in skincare.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Uses:</span>
                    <li>Popular in floral arrangements and bouquets</li>
                    <li>Widely used in perfumes and essential oils</li>
                    <li>Symbolic in cultural and ceremonial events</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold">Appearance:</span>
                    <li>Ruffled petals with a frilly edge</li>
                    <li>Available in a wide range of colors</li>
                    <li>Compact, dense blooms with a spicy scent</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={CarnationsImg} alt="img" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img
                src={OrchidImg}
                alt="Orchid"
                style={{
                  borderRadius: "40%", // For a circular shape
                  width: "100%", // Adjust as needed
                  height: "auto", // Maintain aspect ratio
                }}
                className="img-fluid p-3"
              />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Orchids
              </h2>
              <p className="text-center ">
                Elegant and resilient, orchids are a premium choice in the
                floral industry, often used in high-end arrangements and luxury
                decor for their long-lasting beauty and symbolic value.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Uses:</span>
                    <li>Popular in luxury floral arrangements and decor</li>
                    <li>
                      Utilized in high-end cosmetics and skincare products
                    </li>
                    <li>Symbolic in various cultures and ceremonial events</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold">Appearance:</span>
                    <li>Elegant, diverse shapes with intricate patterns</li>
                    <li>Available in a wide spectrum of vibrant colors</li>
                    <li>
                      Delicate blooms with a subtle, often exotic fragrance
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Anthurium
              </h2>
              <p className="text-center">
                Renowned for its striking appearance and long-lasting blooms,
                the anthurium is a stunning addition to any floral arrangement.
              </p>

              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Uses:</span>
                    <li>Popular in luxury floral arrangements and decor</li>
                    <li>
                      Utilized in high-end cosmetics and skincare products
                    </li>
                    <li>Symbolic in various cultures and ceremonial events</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold">Appearance:</span>
                    <li>Sleek, heart-shaped flowers with a glossy finish</li>
                    <li>Available in rich colors like red, pink, and white</li>
                    <li>Features a distinctive, long-lasting spadix</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={AnthuriumImg} alt="img" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img src={StrelitziaImg} alt="img" className="img-fluid p-3" />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
              Strelitzia
              </h2>
              <p className="text-center ">
                Distinguished by its bold, bird-like blooms and vibrant hues,
                the Strelitzia brings a touch of tropical elegance and visual
                impact to any floral arrangement.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Uses:</span>
                    <li>Ideal for tropical and exotic floral arrangements</li>
                    <li>
                      Popular in interior decor for its dramatic visual appeal
                    </li>
                    <li>
                      Symbolic of paradise and grandeur in various cultures
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold">Appearance:</span>
                    <li>
                      Distinctive, bird-like blooms with vibrant orange and blue
                      colors
                    </li>
                    <li>
                      Large, lush green leaves that complement the striking
                      flowers
                    </li>
                    <li>
                      Graceful and dramatic structure, resembling a bird in
                      flight
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Floriculture;
