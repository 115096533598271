import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Hydroponic_types_img from "../../Assets/Hydroponics_types_img.png";
import "./blog.css";
import "animate.css";
import { Link } from 'react-router-dom';
import "bootstrap-icons/font/bootstrap-icons.css";

function Types_of_Hydroponics_Systems() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={Hydroponic_types_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "70%" }}
          alt="hydroponic farm setup"
        />
      </div>

      <Row className="m-5">
        <Col className="p-4 textJustify" sm={9} xs={12} id="content">
          <div id="blog1" className="border p-2">
            <h1 className="text-center p-3">Types of Hydroponic Systems</h1>{" "}
            <p className="pb-4">
              Hydroponics is a method of growing plants without soil, using
              nutrient-rich water to deliver essential nutrients directly to the
              roots. This approach can significantly enhance growth rates and
              yields. There are several types of hydroponic systems, each with
              its unique advantages and applications. Here’s a detailed overview
              of the most common hydroponic systems:
            </p>
            <ol>
              <li className="mb-4">
                <span className="fw-bold"><Link to="/NftAframe" style={{ textDecoration: 'none'}}>Nutrient Film Technique (NFT)</Link></span>
                <h6>How It Works:</h6>
                <ul>
                  <li>Plants are placed in a sloped trough or channel.</li>
                  <li>
                    A thin film of nutrient solution continuously flows over the
                    plant roots, providing them with essential nutrients and
                    oxygen.
                  </li>
                </ul>
                <h6>Advantages:</h6>
                <ul>Efficient nutrient and water usage.</ul>
                <ul>Promotes rapid growth and high yields.</ul>

                <h6>Applications:</h6>
                <ul>
                  <li>Ideal for growing leafy greens and herbs.</li>
                </ul>
                <h6>Considerations:</h6>
                <ul>
                  <li>
                    Requires careful monitoring to prevent nutrient imbalances
                    and pump failures.
                  </li>
                </ul>
              </li>

              <li className="mb-4">
                <span className="fw-bold"><Link to="/DWC" style={{ textDecoration: 'none'}}>Deep Water Culture (DWC)</Link></span>
                <h6>How It Works:</h6>
                <ul>
                  <li>
                    Plant roots are submerged in a nutrient-rich oxygenated
                    water reservoir.
                  </li>
                  <li>Air stones or diffusers provide oxygen to the roots.</li>
                </ul>
                <h6>Advantages:</h6>
                <ul>
                  <li>Simple setup and maintenance.</li>
                  <li>Excellent oxygenation and nutrient uptake.</li>
                </ul>
                <h6>Applications:</h6>
                <ul>
                  <li>
                    Suitable for a wide range of crops, including lettuce,
                    tomatoes, and cucumbers.
                  </li>
                </ul>
                <h6>Considerations:</h6>
                <ul>
                  <li>
                    Requires consistent monitoring of water pH and nutrient
                    levels.
                  </li>
                </ul>
              </li>
              <li className="mb-4">
                <span className="fw-bold">Ebb and Flow (Flood and Drain)</span>
                <h6>How It Works:</h6>
                <ul>
                  <li>
                    Plants are placed in a grow tray, and the nutrient solution
                    is periodically flooded into the tray.
                  </li>
                  <li>The solution then drains back into a reservoir.</li>
                </ul>
                <h6>Advantages:</h6>
                <ul>
                  <li>Efficient use of water and nutrients.</li>
                  <li>Promotes strong root growth.</li>
                </ul>
                <h6>Applications:</h6>
                <ul>
                  <li>
                    Versatile and can be used for a variety of crops, including
                    herbs and vegetables.
                  </li>
                </ul>
                <h6>Considerations:</h6>
                <ul>
                  <li>Requires a reliable pump system and timer.</li>
                </ul>
              </li>
              <li className="mb-4">
                <span className="fw-bold">Wick System</span>
                <h6>How It Works:</h6>
                <ul>
                  <li>
                    Nutrient solution is drawn up from a reservoir to the plant
                    roots through a wick or string.
                  </li>
                </ul>
                <h6>Advantages:</h6>
                <ul>
                  <li>Simple and inexpensive setup.</li>
                  <li>Low maintenance and no need for pumps.</li>
                </ul>
                <h6>Applications:</h6>
                <ul>
                  <li>
                    Best suited for small-scale setups and growing herbs and
                    small vegetables.
                  </li>
                </ul>
                <h6>Considerations:</h6>
                <ul>
                  <li>
                    Limited by the ability of the wick to deliver nutrients
                    efficiently.
                  </li>
                </ul>
              </li>
              <li className="mb-4">
                <span className="fw-bold">Drip System</span>
                <h6>How It Works:</h6>
                <ul>
                  <li>
                    Nutrient solution is delivered directly to the base of each
                    plant through drip emitters or tubes.
                  </li>
                  <li>Excess solution drains away from the root zone.</li>{" "}
                </ul>
                <h6>Advantages:</h6>
                <ul>
                  <li>Provides precise control over nutrient delivery.</li>
                  <li>Suitable for a wide range of plants and scales.</li>
                </ul>
                <h6>Applications:</h6>
                <ul>
                  <li>
                    Ideal for larger scale operations and growing a variety of
                    crops.
                  </li>
                </ul>
                <h6>Considerations:</h6>
                <ul>
                  <li>
                    Requires regular maintenance to prevent clogging of drippers
                  </li>
                </ul>
              </li>
              <li className="mb-4">
                <span className="fw-bold"><Link to="/DutchBucket" style={{ textDecoration: 'none'}}>Dutch Bucket System</Link></span>
                <h6>How It Works:</h6>
                <ul>
                  <li>
                    Plants are grown in individual buckets filled with a growing
                    medium.
                  </li>
                  <li>
                    Nutrient solution is delivered to each bucket via a drip
                    system and drains back into a central reservoir
                  </li>
                </ul>
                <h6>Advantages:</h6>
                <ul>
                  <li>Flexible and scalable.</li>
                  <li>
                    Good for growing larger plants, such as tomatoes and
                    peppers.
                  </li>
                </ul>
                <h6>Applications:</h6>
                <ul>
                  <li>
                    Effective for crops that require more space and support.
                  </li>
                </ul>
                <h6>Considerations:</h6>
                <ul>
                  <li>
                    Requires careful management of the nutrient solution and pH
                    levels.
                  </li>
                </ul>
              </li>
              <li className="mb-4">
                <span className="fw-bold">Vertical Farming Systems</span>
                <h6>How It Works:</h6>
                <ul>
                  <li>
                    Plants are grown in vertically stacked layers or towers.
                  </li>
                  <li>
                    Nutrient solution is delivered to each layer, and excess
                    solution is recirculated.
                  </li>
                </ul>
                <h6>Advantages:</h6>
                <ul>
                  <li>
                    Maximizes space usage and increases yield per square meter.
                  </li>
                  <li>Can be integrated with various hydroponic techniques.</li>
                </ul>
                <h6>Applications:</h6>
                <ul>
                  <li>Ideal for urban farming and high-density cultivation.</li>
                </ul>
                <h6>Considerations:</h6>
                <ul>
                  <li>Initial setup can be complex and expensive.</li>
                </ul>
              </li>
            </ol>
            <h4>Conclusion</h4>
            <p>
              Each hydroponic system has its strengths and is suited to
              different types of crops and growing environments. When selecting
              a system, consider factors such as crop type, available space, and
              resource efficiency. Whether you’re a beginner or an experienced
              grower, choosing the right hydroponic system can lead to a
              successful and productive growing experience.
            </p>
            <p>
              For more tailored advice on hydroponic systems and setup, feel
              free to contact us at Royal Green House Construction Pvt Ltd
              (RGCPL). Our team of experts is here to guide you through the best
              options for your needs. Reach us at{" "}
              <a href="tel:+919960991166"> +91-9960-991-166</a>.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Types_of_Hydroponics_Systems;
