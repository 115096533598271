import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Enquiry from "../../Components/Enquiry";

function RequestAQuote() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Request A Quote
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                Request A Quote
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="p-3">
            <h3 className="text-center p-3">
              LEADER IN VERTICAL HYDROPONIC FARMING WITH 40 TO 60% HIGHER
              REVENUE.
            </h3>
            <h4 className="text-center ">
              WITH 5000SQFT AREA ANYWHERE IN INDIA,
            </h4>
            <h4 className="text-center ">
              YOU CAN BUILD AN EXTREMELY PROFITABLE HYDROPONIC FARM.
            </h4>
            <h4 className="text-center p-3">
              SOILLESS FARMING – SUSTAINABLE AGRICULTURE – CONTRACT FARMING
            </h4>
          </Col>
        </Row>
      </Container>
      <Enquiry />
    </>
  );
}

export default RequestAQuote;
