import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.png";
import CardImg2 from "../../Assets/step2.png";
import CardImg3 from "../../Assets/step3.png";
import CardImg4 from "../../Assets/step4.png";
import Nvph1 from "../../Assets/Nvph1.jpg";
import gerberanew from "../../Assets/gerberanew.png";
import Nvph2 from "../../Assets/Nvph2.jpg";
import Roses from "../../Assets/Roses.jpeg";
import NvphStructure from "../../Assets/NvphStructure.png";
import NvphVents from "../../Assets/NvphVents.png";
import NvphStructure2 from "../../Assets/NvphStructure2.png";
import NvphFoundation from "../../Assets/NvphFoundation.png";
import NvphAluminet from "../../Assets/NvphAluminet.png";
import NvphInsectNet from "../../Assets/NvphInsectNet.png";
import NvphShadenet from "../../Assets/NvphShadenet.png";
import NvphFanpad1 from "../../Assets/NvphFanpad1.png";
import NvphCoolingFan from "../../Assets/NvphCoolingFan.png";
import SecondaryAutomation from "../../Assets/SecondaryAutomation.png";
import NvphCovering from "../../Assets/gallery/NvphCovering.png";

import Enquiry from "../../Components/Enquiry";
// import Accordion from 'react-bootstrap/Accordion';
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import NvphImg from "../../Assets/gallery/serv_nvph1.png";
import Accordion from "react-bootstrap/Accordion";
import "../../App.css";
function Nvph() {
  return (
    <>
      <Container fluid>
        <Row className="headingimgnew">
          <Col className="m-5">
            <h1 className="text-dark m-4 mx-4 px-2 fw-bold text-center">
              {" "}
              Naturally Ventilated Polyhouse
            </h1>
            <div className="text-dark m-4 fw-bold text-center">
              <a href="/" className=" text-dark m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-dark text-decoration-none m-3">
                Naturally Ventilated Polyhouse
              </a>
            </div>
          </Col>
        </Row>
        <Row className="m-5 ps-5">
          <h1>DESIGN</h1>
          <p className="display-6 fst-italic">Future Of Polyhouse Design</p>
          <p className="fw-bold display-1 text-warning">
            The Designs made for India
          </p>
        </Row>

        <Row className="justify-content-center">
          <Col lg={8} md={10} sm={12} className="p-1 ">
            <img
              src={NvphStructure}
              alt="Naturally ventilated polyhouse, NVPH structure, *Custom Design: We offer **customizable NVPH designs* tailored to your specific crop requirements and local climate conditions."
              className="img-fluid rounded"
            />
          </Col>
          <Row className="justify-content-center p-1">
            <Col
              lg={4}
              md={6}
              sm={12}
              className="p-2  d-flex align-items-center justify-content-center"
            >
              <img
                src={gerberanew}
                alt="Floriculture farming in polyhouse, Ventilation system for NVPH"
                className="img-fluid rounded w-100 h-100 object-cover"
              />
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              className="p-2  d-flex align-items-center justify-content-center"
            >
              <img
                src={Roses}
                alt="Floriculture farmimg in polyhouse, Horticultural Innovation, - *Sustainable Farming: Our **NVPH solutions* reduce reliance on artificial energy sources, contributing to sustainable and eco-friendly farming practices.
"
                className="img-fluid rounded w-100 h-100 object-cover"
              />
            </Col>
          </Row>
        </Row>

        <Row>
          <Col className="m-5 mb-2">
            <h6 className="text-green m-2">Naturally Ventilated Polyhouse</h6>
            <h2 className="m-2">Naturally Ventilated Polyhouse</h2>

            <Row>
              <Col lg={6}>
                <p className="text-muted m-2">
                  {" "}
                  The naturally ventilated Poly house was designed to meet the
                  growing needs of farmers around the world to produce higher
                  quality crops of vegetable & flower thus enhance the return.
                </p>
                <p className="text-muted m-2">
                  The naturally ventilated poly house is designed to solve the
                  problem of ventilation and humidity and is especially suited
                  for hot and humid weather conditions. This is carried out by
                  Fix openings located in the center of each of the structure's
                  arches which run's along the entire length and four sides
                  ventilation, which run's along the entire length & breadth of
                  the structure.
                </p>
                <p className="text-muted m-2">
                  These openings allow natural ventilation and release hot air.
                  This type of poly house can withstand winds of 100-120
                  kilometers per hour. The naturally ventilated Poly House has a
                  number of variations depending on the climate zone and type of
                  crop.{" "}
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img
                  src={NvphImg}
                  alt="Naturally ventilated polyhouse farming"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="justify-content-center p-5">
          {/* This row centers the content */}
          <Col lg={10} md={10} sm={12}>
            <Row className="text-left">
              <Col>
                <p className="fw-bold display-5">VENTILATION</p>
                <p className="display-6">
                  Improved air circulation is achieved by taller vents.
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                lg={10}
                md={10}
                sm={10}
                className="d-flex flex-column justify-content-center align-items-center pt-2"
              >
                <img
                  src={NvphVents}
                  alt="Naturally ventilated polyhouse vents, Improved Crop Quality*: With controlled ventilation and light, your crops receive the best conditions for growth, resulting in higher quality produce."
                  className="img-fluid rounded"
                  style={{ width: "1500px", height: "auto" }}
                />
              </Col>
            </Row>

            <Row className="pt-5">
              <Col>
                <div className="text-dark p-3 mt-3">
                  <h4 className="fw-bold">STRUCTURE</h4>
                  <h3 className="fw-bold pt-4 pb-2">
                    Upto 7 meter Tall Structure
                  </h3>
                  <p className="text-justify">
                    At Royal Green House Construction Pvt Ltd (RGCPL), we adhere
                    to a bay size of 8m x 4m, with the NVPH width being 35% of
                    the desired length. This design ensures ample sunlight,
                    airflow, and humidity control. We maintain a ridge height of
                    6 to 7 meters and place the gutter at 4 to 5 meters. Our
                    structural design is robust enough to withstand wind speeds
                    of at least 120 km/hr and a minimum load of 25kg/m².
                  </p>
                </div>
              </Col>
              <Col>
                <img
                  src={NvphStructure2}
                  alt="NVPH structure, *High-Quality Materials: Our polyhouses are built using durable **GI pipes* and premium *Polyfilm*, ensuring long-lasting performance even under challenging weather conditions."
                  className="img-fluid rounded mt-3"
                  style={{ width: "600px", height: "auto" }}
                />
              </Col>
            </Row>

            <Row className="pt-5">
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={NvphFoundation}
                  alt=" Naturally ventilated polyhouse Stronger Foundation, - *Durable Construction*: Built with high-quality GI pipes and Polyfilm, ensuring longevity and resistance to harsh weather.
"
                  className="img-fluid rounded"
                  style={{ width: "100%", maxWidth: "800px", height: "auto" }}
                />
              </Col>
              <Col md={6}>
                <h4 className="pt-3 fw-bold">Stronger Foundation</h4>
                <p className="text-justify">
                  At Royal Green House Construction Pvt Ltd (RGCPL), we ensure
                  strong and stable polyhouse foundations by using GI pipes with
                  a minimum outer diameter of 60 mm or more. The foundation
                  depth is at least 75 cm, adjusted based on soil type and
                  prevailing wind conditions. These pipes are grouted with a
                  cement concrete mixture of 1:2:4, utilizing telescopic
                  insertion of the column. This approach guarantees a durable
                  foundation, crucial for the long-term stability of the
                  polyhouse structure.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="m-5">
          <Col md={7} className="pe-5">
            <img
              src={NvphCoolingFan}
              alt="NVPH cooling solutions, Optimal Ventilation: With strategically placed ventilation systems, our **naturally ventilated polyhouses* ensure excellent airflow, reducing the need for additional cooling systems.
"
              style={{ width: "100%", height: "auto" }}
              className="img-fluid rounded"
            />
          </Col>
          <Col md={4} className="pt-5">
            <h3 className="fw-bold animationForText">COOLING SOLUTION</h3>
            <h2 className="pt-4 text-warning">RGCPL Air Circulation Fan</h2>
            <p className="pt-2">
              At the speed of 935 RPM/min, the air flow reaches 22m of distance
              breaking the humidity pockets at ease in your polyhouse.
            </p>
          </Col>
        </Row>

        <Row className="px-5">
          <Col md={5} className="pt-3">
            <h3 className="fw-bold animationForText">COVERING SOLUTION</h3>

            <h2 className="pt-4 text-warning">
              Ginegar/Suncool/Essen/Greenprobe Polyfilm
            </h2>
            <p className="pt-2 text-justify">
              At Royal Green House Construction Pvt Ltd (RGCPL), we use Polyfim
              with advanced properties that offer multiple benefits:
              <ul>
                <li>
                  Thermal effect (IR) enhances temperature control by reducing
                  heat loss, keeping the interior warmer during cooler
                  conditions.
                </li>{" "}
                <li>
                  Anti mist prevents the formation of condensation droplets,
                  ensuring clear visibility and consistent light transmission.
                </li>
                <li>
                  Anti dust reduces the accumulation of dust on the surface,
                  maintaining the film's transparency and effectiveness over
                  time.
                </li>{" "}
                <li>
                  Light diffusion ensures even distribution of light, minimizing
                  shadows and promoting uniform plant growth.
                </li>{" "}
                <li>
                  Anti virus effect provides an additional layer of protection
                  against viruses, contributing to healthier crop production.
                </li>
              </ul>
            </p>
          </Col>

          <Col md={7} className="px-5">
            <img
              src={NvphCovering}
              alt="NVPH covering solutions, Light Diffusion Technology: Our Polyfilm includes **light diffusion* properties, ensuring even sunlight distribution, reducing plant stress, and promoting uniform growth. Agricultural Polyfilm"
              style={{ width: "1000px", height: "auto" }}
              className="mx-5 img-fluid rounded"
            />
          </Col>
        </Row>

        <div>
          <Row className="text-center mb-4">
            <Col>
              <h3 className="fw-bold animationForText">SHADING SOLUTION</h3>
            </Col>
          </Row>
          <Row className="g-3">
            <Col>
              <div className="text-center">
                <img
                  src={NvphAluminet}
                  alt="NVPH shading solutions, UV Stabilized Films"
                  className="img-fluid rounded"
                />
                <h4 className="mt-0 mb-0">RGCPL Aluminet</h4>
              </div>
            </Col>
            <Col>
              <div className="text-center">
                <img
                  src={NvphShadenet}
                  alt="Naturally ventilated polyhouse shadenet, Shadenet Installation"
                  className="img-fluid rounded"
                />
                <h4 className="mt-0 mb-0">RGCPL Shadenet</h4>
              </div>
            </Col>
            <Col>
              <div className="text-center">
                <img
                  src={NvphInsectNet}
                  alt="Insect Netting, Naturally ventilated polyhouse insect net"
                  className="img-fluid rounded"
                />
                <h4 className="mt-0 mb-0">RGCPL Insect Net</h4>
              </div>
            </Col>
          </Row>
        </div>

        <div>
          <Row className="text-center mb-4 mt-5">
            <Col>
              <h3 className="text-shadow fw-bold animationForText">
                MOTORIZE SYSTEM
              </h3>
            </Col>
          </Row>
          <Row className="g-3 m-5">
            <Col>
              <div className="text-center">
                <img
                  src={NvphFanpad1}
                  alt="Temperature Regulation, NVPH automized side vents"
                  className="img-fluid rounded"
                />
                <h4 className="mt-0 mb-0">Side Vent Automation</h4>
              </div>
            </Col>
            <Col>
              <div className="text-center">
                <img
                  src={SecondaryAutomation}
                  alt="NVPH secondary layer automation, Enhanced Crop Yield: By maintaining optimal temperature and humidity levels, our NVPH structures boost crop productivity."
                  className="img-fluid rounded"
                />
                <h4 className="mt-0 mb-0">Secondary Layer Automation</h4>
              </div>
            </Col>
          </Row>
        </div>

        <Row className="mt-5">
          <Col lg={12} className=" p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and A frame system now we can
                      grow 70 percent more food in the same area.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow anywhere you want
                    </Card.Title>
                    <Card.Text>
                      We can now grow our food anywhere with help of just water
                      and protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-hand-holding-droplet text-green text-center fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Water Efficiency
                    </Card.Title>
                    <Card.Text>
                      Now grow crops on 90% less water compared to field crops.
                      This farming is possible at any location even in water
                      scared zones.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-bolt text-green text-center fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      High value cash crops
                    </Card.Title>
                    <Card.Text>
                      Not only our daily crops but we can grow super-crops or
                      very high value cash crops with controlled environment.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h6 className="text-green m-2 text-center">
              Our Process In Some Steps
            </h6>
            <h4 className="m-2 text-center fw-bold">
              Building Commercially Successful Hydroponic Farm
            </h4>
          </Col>
        </Row>
        <Row className="p-2 m-3">
          {/* second cards */}
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg1}  alt="Step One" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Site Feasibility
                </Card.Title>
                <Card.Text className=" text-white p-1">
                  We help you identify a site location to set up our hydroponic
                  project. We consider locality, levelling of land, examine
                  electricity and water also gather basic information of
                  surrounding that can help us test feasibility.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg2}  alt="Step Two" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Ideal Farm Construction
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once financial feasibility is analysed we start working on the
                  Construction of the Project. Based on site feasibility and
                  market research we choose between NVPH or Fan-Pad system and
                  also hydroponic system between NFT or Dutch Bucket.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg3}  alt="Step Three" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Farm Management{" "}
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once construction is completed, we work on automating the farm
                  using IoT devices as well as integrating with farm management.
                  At this stage, we also start training your field team to make
                  sure we continuously grow high quality produce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg4}  alt="Step Four" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Contract & Markerting Your Product
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once our field team is ready and the complete farm is
                  automated, Our team of agronomists, sales and planning starts
                  working on the contract and set a selling mechanism for your
                  farm, with a schedule of crops.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="container my-4 d-flex justify-content-center">
              <div className="table-responsive">
                <h3 className="text-center">
                  <kbd>Physical Specs</kbd>
                </h3>
                <table className="table table-custom table-striped table-bordered border-dark text-center">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Specification</th>
                      <th scope="col">Options/Range</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Gutter Height</td>
                      <td>4m - 5m</td>
                    </tr>
                    <tr>
                      <td>Ridge Height</td>
                      <td>6m - 7m</td>
                    </tr>
                    <tr>
                      <td>Top Vent</td>
                      <td>1m - 1.5m</td>
                    </tr>
                    <tr>
                      <td>Grid Size</td>
                      <td>8m X 4m</td>
                    </tr>
                    <tr>
                      <td>Gutter Slope</td>
                      <td>1.4% - 2%</td>
                    </tr>
                    <tr>
                      <td>Gutter Thickness</td>
                      <td>1.5mm/1.6mm/1.8mm/2mm RGCPL Processed</td>
                    </tr>
                    <tr>
                      <td>Apron Height</td>
                      <td>0.5m/1m/1.5m</td>
                    </tr>
                    <tr>
                      <td>Side Ventilation</td>
                      <td>3m/3.5m/4m</td>
                    </tr>
                    <tr>
                      <td>GI Pipe</td>
                      <td>1000GSM - 1200GSM</td>
                    </tr>
                    <tr>
                      <td>Nuts, Bolts and Connectors</td>
                      <td>High Tensile Strength and Galvanized</td>
                    </tr>
                    <tr>
                      <td>Foundation</td>
                      <td>60 OD with 2mm Thickness</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
          <Col>
            <div className="container my-4 d-flex justify-content-center">
              <div className="table-responsive">
                <h3 className="text-center">
                  <kbd>Structural Specs</kbd>
                </h3>
                <table className="table table-custom table-striped table-bordered border-dark text-center">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Specification</th>
                      <th scope="col">Options/Range</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Cooling System</td>
                      <td>4 RGCPL Cooling Systems</td>
                    </tr>
                    <tr>
                      <td>Cladding Material</td>
                      <td>RGCPL Ginegar Polyfilm</td>
                    </tr>
                    <tr>
                      <td>Shadenet</td>
                      <td>RGCPL Aluminet/Shade Net</td>
                    </tr>
                    <tr>
                      <td>Insect Net</td>
                      <td>Insect Net (40 Mesh, 50 Mesh)</td>
                    </tr>
                    <tr>
                      <td>Top Vent</td>
                      <td>Fixed</td>
                    </tr>
                    <tr>
                      <td>Side Curtain</td>
                      <td>Manual/Semi-automatic (Gear)/Automized</td>
                    </tr>
                    <tr>
                      <td>Secondary Layer</td>
                      <td>Standard Manual/Manual with Accessories/Automized</td>
                    </tr>
                    <tr>
                      <td>Trellising</td>
                      <td>RGCPL Trellising</td>
                    </tr>
                    <tr>
                      <td>Construction</td>
                      <td>GI Pipe Frame</td>
                    </tr>
                    <tr>
                      <td>Door</td>
                      <td>RGCPL Aluminum Frame Double Sliding Door</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>

        {/* Acordian */}
        <Row>
          <h4 className="m-2 text-center fw-bold">NVPH Specifications</h4>

          <Col lg={6}>
            <Accordion flush className="m-4 p-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>What is the type of system?</Accordion.Header>
                <Accordion.Body className="text-muted">
                  This is NVPH (Natural Ventilated Poly House). Where
                  temperature is maintained by creating vents on top side of
                  poly house.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What design style is being used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We use ‘Butterfly Design’ style to create this NVPH. Butterfly
                  design is proven design to withstand wind speed upto 65km/hr.
                  Also Maintenance requirement for butterfly design is lower
                  compared to other designs.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Where can you construct this system?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We can construct this almost anywhere where we can find strong
                  base. Mostly NVPH is installed on land, terrace, any hard
                  surface or concrete foundation.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  What is the bay size of NVPH?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We strictly follow bay size of 8m X 4m (Width of NVPH should
                  be 35% of the desired length) as it provides ample of
                  sunlight, air flow and humidity.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  What is the ridge height of structure?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We keep ridge height of playhouse at 6.5 meter.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>How ridge vent is planned? </Accordion.Header>
                <Accordion.Body className="text-muted">
                  In this butterfly design vents are top open.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>What is gutter height? </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Gutter is placed at 4.5meter.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  What gutter slope is maintained?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Gutter slope is maintained at 2%.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  What structure material is used?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Entire structure is made up of GI (Galvanised Iron) Material.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  What aerodynamics is considered for designing the structure?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  The structural design will be sound enough to withstand wind
                  speed minimum 120 km/hr and minimum load of 25kg/m2.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  What measures are considered to develop the structure?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Complete structure made of galvanized steel tubular pipes of
                  equivalent section conforming Indian Standards having wall
                  thickness 2mm.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  What thickness of columns is used?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  All columns are 76 mm outer diameter, with 2mm of thickness.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  What are trusses specification?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Bottom & top cord are of 60 mm outer diameter with 2 mm
                  thickness.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                  What trusses member & Purlin specification used?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Trusses members and purlin are of 48mm and 42 mm outer
                  diameter with 2mm thickness.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <Col lg={6}>
            <Accordion flush className="m-4 p-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What specifications are followed for purlin members?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Purline members are of 33mm and 25 mm with 2mm thickness.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What thickness specifications are used for foundations?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  GI Pipes of minimum 60 mm OD or more to Foundation depth of 75
                  cm or more depending upon soil type and prevailing wind
                  condition, grouted with cement concrete mixture of 1:2:4 using
                  telescopic insertion of column.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What foundation accessories are considered?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  For foundation all nuts & bolts will be of high tensile
                  strength and galvanized materials.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  How entrance room & door are designed?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  One entrance room of size 4m x 3m x 2m (L x W x H) will be
                  provided and covered with 200 micron UV stabilized transparent
                  plastic film.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  What are the size and specification of doors.{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Doors of size 2m width & 2.5m height double leaf made in
                  plastic/FRP sheets mounted in a suitable strong frame.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  What cladding material is used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  UV stabilized 200 micron transparent plastics films.
                  Conforming Indian Standards (IS 15827: 2009), multilayered,
                  anti-drip, anti-fog, anti-sulphur, diffused, clear and having
                  minimum 85% level of light transmittance.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  What measures are used for fixing of cladding material?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  All ends/joints of plastic film will be fixed with a two way
                  aluminium/GI Profile with suitable locking arrangement along
                  with curtain top.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  What quality of spring insert are used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Zigzag high carbon steel with spring action wire of 2-3 mm
                  diameter is inserted to fix the sheet into Aluminium Profile
                  or GI Profile.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  What curtains and Insect screen are used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Roll up UV stabilized 200 micron transparent plastic film as
                  curtains will be provided up 2.5 to 3.0 m height on one side
                  with manual opening and closing of curtains. 40 mesh nylon
                  Insect proof/ mono mono nets (UV stabilized) of equivalent
                  size will be fixed Inside the curtains.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  What quality of shade net is used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Anti UV stabilized 50% shading net with manually operated
                  mechanism for expanding and retracting. Size of the net will
                  be equal to the floor area of the greenhouse.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  How 4-way foggers assembly is used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We use 65 Micron, LPD fitted, High pressure 4-way foggers with
                  lateral piping and PVC headers fittings. High pressure 5.5 KG
                  submersible pump with pressure gauge, Air release valve is
                  used. sand filter etc.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  What quality of vent opener are used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  All vent openers are made up of brass, Pushback auto locking
                  with gear vent opener on all sides are used. Totally 4 number
                  of openers are used.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  What filtering mechanism is used for water filtration?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We use sand filters to filter the water in system to help out
                  filter coco peat or other irregular substances.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                  What kind of GI wires are used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Galvanised GI wire are used for the rope ways.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default Nvph;
