import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import polyhouse_bog_img from "../../Assets/polyhouse_blog_img.png";
import "./blog.css";
import "animate.css";
import { Button } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from 'react-router-dom';

function Polyhouse_Farming_Benefits_and_Setup() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={polyhouse_bog_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "70%" }}
          alt="Blog Image"
        />
      </div>

      <Row className="m-5">
        <Col className="p-4 textJustify" sm={9} xs={12} id="content">
          <div id="blog1" className="border p-2">
            <h1 className="text-center p-3">Polyhouse Farming:</h1>{" "}
            <h2 className="text-center mb-5">
              Benefits and Setup with RGCPL Expertise
            </h2>
            <p className="pb-4">
              Polyhouse farming is revolutionizing agriculture by providing a
              controlled environment that can enhance productivity and quality
              of crops. At Royal Green House Construction Pvt Ltd (RGCPL), we
              leverage our extensive expertise to create optimal polyhouse
              solutions tailored to various farming needs. Here’s a
              comprehensive look at the benefits of polyhouse farming and the
              setup process, showcasing our specialized approach.
            </p>
            <h2>Benefits of Polyhouse Farming</h2>
            <ul>
              <li>
                Controlled Environment Polyhouses create a micro-climate that
                shields crops from harsh weather conditions, pests, and
                diseases. This controlled environment allows for precise
                management of temperature, humidity, and light, which can lead
                to increased yields and better quality produce.
              </li>
              <li>
                Extended Growing Seasons By maintaining optimal conditions
                year-round, polyhouses enable farmers to grow crops out of their
                natural season. This not only increases the number of harvests
                per year but also allows for the cultivation of high-value crops
                that may not thrive in the local climate.
              </li>
              <li>
                Water Efficiency Polyhouse farming typically incorporates
                efficient irrigation systems that reduce water wastage. The use
                of advanced hydroponic systems, such as those offered by RGCPL,
                further enhances water conservation by delivering nutrients
                directly to the plant roots with minimal runoff.
              </li>
              <li>
                Enhanced Pest and Disease Control The enclosed structure of a
                polyhouse minimizes exposure to pests and diseases, reducing the
                need for chemical pesticides and resulting in cleaner, healthier
                produce.
              </li>
              <li>
                Resource Optimization Polyhouses make efficient use of land and
                resources by maximizing space and improving nutrient delivery.
                This results in higher productivity per square meter compared to
                traditional farming methods.
              </li>
            </ul>
            <h2 class="d-inline-block me-3">Polyhouse Setup</h2>
            <h3 class="d-inline-block">:RGCPL Expertise</h3>
            <p>
              Setting up a polyhouse involves careful planning and execution. At
              RGCPL, we adhere to a structured process to ensure the creation of
              high-quality and durable polyhouse structures. Here’s an overview
              of our setup process:
            </p>
            <ol>
              <li>
                <span className="fw-bold">Site Preparation: </span> Before
                construction begins, site preparation is crucial. We ensure the
                land is level and suitable for polyhouse installation. Our team
                conducts a thorough assessment to determine soil type and other
                site-specific conditions.
              </li>
              <li>
                <span className="fw-bold">Foundation Work: </span>Our polyhouse
                foundations are built using GI pipes of a minimum 60 mm OD or
                more, grouted with a cement concrete mixture of 1:2:4. The
                foundation depth is determined based on soil conditions and
                prevailing wind speeds to ensure stability and durability.
              </li>
              <li>
                <span className="fw-bold">Polyhouse Frame Construction: </span>
                We use high-quality materials for the polyhouse frame, ensuring
                a robust structure that can withstand wind speeds of up to 120
                km/hr. Our standard bay size is 8m x 4m, with a ridge height of
                6 to 7 meters and a gutter height of 4 to 5 meters. This design
                maximizes sunlight, airflow, and humidity control.
              </li>
              <li>
                <span className="fw-bold">
                  Installation of Cladding and Accessories:{" "}
                </span>
                We use Polyfim for cladding, which offers features such as
                thermal insulation, anti-mist, anti-dust, and light diffusion.
                The cladding is installed to provide the necessary protection
                and create a suitable growing environment. We also install
                foggers and other necessary accessories to maintain optimal
                conditions.
              </li>
              <li>
                <span className="fw-bold">Hydroponic System Integration: </span>
                RGCPL integrates various hydroponic systems, including <Link to="/NftAframe" style={{ textDecoration: 'none'}}>NFT
                (Nutrient Film Technique)</Link>, <Link to="/DWC" style={{ textDecoration: 'none'}}>DWC (Deep Water Culture)</Link>, and <Link to="/DutchBucket" style={{ textDecoration: 'none'}}>Dutch
                Bucket systems</Link>. Each system is tailored to specific crop needs,
                ensuring efficient nutrient delivery and water use.
              </li>
              <li>
                <span className="fw-bold">
                  Final Inspection and Commissioning:{" "}
                </span>
                Once construction and system installation are complete, we
                conduct a final inspection to ensure everything is functioning
                as intended. We provide training and support to ensure farmers
                can effectively manage their new polyhouse.
              </li>
            </ol>
            <h2>Why Choose RGCPL?</h2>
            <p className="pb-4">
              At Royal Green House Construction Pvt Ltd (RGCPL), our commitment
              to quality and innovation sets us apart. We utilize advanced
              materials and techniques to build polyhouses that meet the highest
              standards. Our expert team provides end-to-end solutions, from
              design and construction to system integration and maintenance.
            </p>
            <h4>By choosing RGCPL, you benefit from:</h4>
            <p className="pb-4">
              Expertise in polyhouse construction and hydroponics<br></br>{" "}
              High-quality materials and advanced technology<br></br>{" "}
              Comprehensive support and training<br></br> Polyhouse farming is a
              game-changer for modern agriculture. With RGCPL’s expertise, you
              can transform your farming practices, optimize resources, and
              achieve exceptional results. For more information or to start your
              polyhouse project, visit our website or contact us directly. For
              inquiries and consultations, contact:
            </p>
            <div className="d-flex align-items-center">
              <Button variant="">
                <i
                  className="bi bi-person-fill"
                  style={{ color: "blue", fontSize: "30px" }}
                ></i>
              </Button>
              <h5 className="mb-0 ms-3">
                Sachin Deshmukh (Operations and Setup):
              </h5>
              <a href="tel:+919890099992" className="ms-2">
                +91-9890099992
              </a>
            </div>
            <div className="d-flex align-items-center">
              <Button variant="">
                <i
                  className="bi bi-person-fill"
                  style={{ color: "blue", fontSize: "30px" }}
                ></i>
              </Button>
              <h5 className="mb-0 ms-3">
              Pramod Kumbhar (Enquiry And Sales):
              </h5>
              <a href="tel:+919922394384" className="ms-2">
              +91-9922394384
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Polyhouse_Farming_Benefits_and_Setup;
