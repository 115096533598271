import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.png";
import CardImg2 from "../../Assets/step2.png";
import CardImg3 from "../../Assets/step3.png";
import CardImg4 from "../../Assets/step4.png";
import Enquiry from "../../Components/Enquiry";
import Accordion from "react-bootstrap/Accordion";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import IndoorVertical from "../../Assets/gallery/lightbag.jpg";
function indoorVertical() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Indoor Vertical Flatbed
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                Indoor Vertical Flatbed
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="m-5 mb-2">
            <h6 className="text-green m-2">Indoor Vertical Flatbed</h6>
            <h2 className="m-2">
              Indoor Vertical Flatbed N.F.T. System with Grow-lights
            </h2>
            {/* <p className='text-muted m-2'>Indoor Vertical H Frame hydroponic farm setup. The indoor hydroponics farm method is a type of controlled environment agriculture which allows you to grow various types of leafy greens and vine crops much more densely than any other outdoor form, where you can save water use by up to 90%, requires 80 to 95 per cent less area, and produce all year round.</p>
                        <p className='text-muted m-2'>
                           Royal Green House is a most recognised brand in the Indian Hydroponics Industry. We install and set up Hydroponics Project Development that allows you to avail the benefits of a completely automated farm with year round production.

                        </p> */}
            <Row>
              <Col lg={6} className="p-3 text-justify">
                {/* <p className='text-muted m-2'>Vertical agriculture is an innovative way of maintaining our agricultural practices. In India, vertical farming is mostly polyhouse-based farming. Poly-house farming is a protected way that gives higher productivity and yield of vegetables and fruits across India. Vertical farming in poly house ensures the high yield with lesser resources saving 80% of water, hugely on labours and with excellent quality. Polyhouse ensures the farming is protected from nature uncertainty and disease without requiring harmful chemicals.</p>
                        <p className='text-muted m-2'>We can plant this crop anytime in the year and hence can produce yield as per market requirements. since production is carried out in a controlled environment with natural nutrients, we get a quality crop. These vertical farms could be installed in densely populated areas where they could supply fresh, healthy food to millions of people all year long, regardless of weather conditions. vertical farms can provide fresh vegetables, herbs, and fruits all year round, alleviating some of the stress of conventional agriculture.</p> */}

                <p className="text-muted ">
                  An Indoor Vertical Flatbed N.F.T. System with Grow Lights is a
                  compact and efficient way to grow plants indoors. It uses a
                  vertical design with horizontal troughs to hold plants. The
                  Nutrient Film Technique (N.F.T.) supplies plant roots with a
                  continuous flow of nutrient-rich water. LED grow lights
                  provide artificial light for photosynthesis. A pump circulates
                  the nutrient solution, and a reservoir collects excess.
                  Benefits include space efficiency, year-round growth, and
                  precise control over growing conditions, but it requires
                  careful monitoring and expertise in hydroponics and lighting.
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img
                  src={IndoorVertical}
                  alt="vine"
                  className="img-fluid rounded "
                />
              </Col>
            </Row>
          </Col>
        </Row>{" "}
        <Row>
          <Col lg={12} className=" p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and A frame system now we can
                      grow 70 percent more food in the same area.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow anywhere you want
                    </Card.Title>
                    <Card.Text>
                      We can now grow our food anywhere with help of just water
                      and protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-hand-holding-droplet text-green text-center fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title className="text-center">Less water</Card.Title>
                    <Card.Text>
                      Now grow crops on 90% less water compared to field crops.
                      This farming is possible at any location even in water
                      scared zones.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-bolt text-green text-center fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      High value cash crops
                    </Card.Title>
                    <Card.Text>
                      Not only our daily crops but we can grow super-crops or
                      very high value cash crops with controlled environment.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h6 className="text-green m-2 text-center">
              Our Process In Some Steps
            </h6>
            <h4 className="m-2 text-center fw-bold">
              Building Commercially Successful Hydroponic Farm
            </h4>
          </Col>
        </Row>
        <Row className="p-2 m-3">
          {/* second cards */}
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg1}  alt="Step One" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Site Feasibility
                </Card.Title>
                <Card.Text className=" text-white p-1">
                  We help you identify a site location to set up our hydroponic
                  project. We consider locality, levelling of land, examine
                  electricity and water also gather basic information of
                  surrounding that can help us test feasibility.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg2}  alt="Step Two" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Ideal Farm Construction
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once financial feasibility is analysed we start working on the
                  Construction of the Project. Based on site feasibility and
                  market research we choose between NVPH or Fan-Pad system and
                  also hydroponic system between NFT or Dutch Bucket.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg3}  alt="Step Three" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Farm Management{" "}
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once construction is completed, we work on automating the farm
                  using IoT devices as well as integrating with farm management.
                  At this stage, we also start training your field team to make
                  sure we continuously grow high quality produce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg4}  alt="Step Four" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Contract & Markerting Your Product
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once our field team is ready and the complete farm is
                  automated, Our team of agronomists, sales and planning starts
                  working on the contract and set a selling mechanism for your
                  farm, with a schedule of crops.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default indoorVertical;
