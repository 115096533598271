import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import fanbadaFrame from "../../Assets/gallery/1.png";
import "./blog.css";
import "animate.css";
import { Link } from 'react-router-dom';

function FanpadAFrame_Blog() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={fanbadaFrame}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "70%" }}
          alt="Blog Image"
        />
      </div>

      <Row className="m-5">
        <Col className="p-4 textJustify" sm={9} xs={12} id="content">
          <div id="blog1" className="border p-2">
            <h1 className="text-center p-3">FANPAD A Frame</h1>
            <p>Introduction</p>
            <p>
              In the realm of hydroponics, where innovation is key to
              sustainable agriculture, the FANPAD A-Frame stands out as a
              game-changer. This cutting-edge hydroponic system combines smart
              design, space efficiency, and eco-conscious principles to redefine
              how crops are grown. By harnessing the power of technology and
              nature, the FANPAD A-Frame sets new standards for productivity,
              resource optimization, and environmental responsibility in
              hydroponics.
            </p>
            <h5>
              The Concept Behind <Link to="/FanbadAframe" style={{ textDecoration: 'none'}}>FANPAD A-Frame</Link>
            </h5>

            <p>
              The FANPAD A-Frame is a revolutionary hydroponic farming structure
              designed to maximize space utilization while maintaining optimal
              conditions for plant growth. Its name derives from the unique
              A-shaped structure and the principle of airflow circulation within
              the frame. This innovative design enables farmers to grow crops
              vertically, making the most of limited land resources and ushering
              in a new era of sustainable agriculture.
            </p>
            <h5>Key Features and Advantages</h5>
            <p>
              <span className="fw-bold">
                1.Vertical Farming Efficiency:
              </span>{" "}
              Traditional horizontal hydroponic setups can consume significant
              land space, leading to deforestation and land degradation. The
              FANPAD A-Frame eliminates this issue by enabling vertical farming.
              By stacking multiple tiers of crops, it increases the farming area
              without expanding horizontally, maximizing yield per square foot
              of land.
            </p>
            <p>
              <span className="fw-bold">2.Optimized Airflow:</span> The
              A-Frame design enhances airflow circulation within the structure,
              ensuring adequate ventilation and reducing the risk of pests and
              diseases. This optimized airflow also contributes to more
              efficient transpiration, leading to healthier and faster-growing
              plants.
            </p>
            <p>
              <span className="fw-bold">3.Smart Irrigation System:</span>{" "}
              The FANPAD A-Frame is equipped with a sophisticated smart
              irrigation system that precisely delivers the right amount of
              water and nutrients to each plant. This system minimizes water
              wastage, promotes water conservation, and reduces the need for
              chemical fertilizers.
            </p>
            <p>
              {" "}
              <span className="fw-bold">
                4.Modularity and Scalability:
              </span>{" "}
              The FANPAD A-Frame system is highly modular and scalable. Farmers
              can customize the size of their setup according to their needs,
              whether it's a small-scale urban farm or a large commercial
              hydroponic facility.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default FanpadAFrame_Blog;
