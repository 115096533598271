import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Brochure from "../../Assets/royalbro.pdf";
import { Link } from "react-router-dom";
import styles from './footer.module.css'; // Ensure you import your CSS
import "../../App.css";

const columnLinks = [
  ["Bangalore", "Chennai", "Delhi NCR", "Hyderabad", "Kolkata", "Mumbai", "Ahmedabad", "Pune", "Navi Mumbai", "Nashik"],
  ["Lucknow", "Surat", "Ahmedabad","Aurangabad", "Chandigarh", "Dehradun", "Hubli", "Jammu", "Nagpur", "Satara"],
  ["Ludhiana", "Goa", "Nanded", "Nellore", "Noida", "Jalna", "Rajkot", "Salem", "Kolhapur", "Sangli"],
  ["Thrissur", "Ujjain", "Vadodara", "Varanasi", "Thane", "Vellore", "Warangal", "Mysore", "Bhiwandi", "Srinagar"]
];

const additionalColumns = [
  ["Jhansi", "Jabalpur", "Indore", "Ahmednagar", "Solapur", "Ratnagiri", "Udupi", "Mysuru", "Ooty", "Tiruchirappalli", "Pondicherry", "Rameswaram", "Panaji", "Latur", "Nanded", "Chandrapur", "Amravati", "Lonavala"],
  ["Leh", "Srinagar", "Gulmarg", "Dharmshala", "Mandi", "Shimla", "Manali", "Amritsar", "Ferozpur", "Patiala", "Jamnagar", "Dwarka", "Surat", " Dhule", "Khandala", "Mahabaleshwar", "Daman", "Junagadh"],
  ["Bathinda", "Mussoorie", "Haridwar", "Nainital", "Rohtak", "Gurugram", "Mathura", "Agra", "Ayodhya", "Kanpur", "Bhavnagar", "Jodhpur", "Jaisalmer", "Mizoram", "Imphal", "Sikkim", "Kathmandu", "Ajmer"],
  ["Prayagraj", "Darjeeling", "Gangtok", "Guwahati", "Tripura", "Patna", "Bhagalpur", "Nalanda", "Cuttack", "Gwalior", "Kota", "Udaipur", "Sawai Madhopur", "pokhara", "Kargil", "Kurnool", "Rourkela", "Ambala"] // Ensure this array has actual city names
];

const Footer = () => {
  const [isVisible, setIsVisible] = useState({
    0: false, // State for column 1
    1: false, // State for column 2
    2: false, // State for column 3
    3: false  // State for column 4
  });

  const toggleVisibility = (columnIndex) => {
    setIsVisible(prevState => ({
      ...prevState,
      [columnIndex]: !prevState[columnIndex]
    }));
  };

  return (
    <footer>
      <Container fluid className="text-white footerimg2">
        <Row className="p-3">
          <Col lg={3}>
            <h6 className="py-3">Immediate Contact</h6>
            <div className="lightTxt py-2">Visit Us (09:00AM to 06:00PM)</div>
            <span className="d-block d-sm-inline-block">
              <i className="fa-solid fa-mobile text-white"></i>
              <Link to="tel:0000-000-000" className="text-green text-decoration-none">
                {" "} +91-9960-991-166
              </Link>
            </span>
            <p className="pt-1 lightTxt">
              #S2/A, Sai Ratna Building, Survey No 517/5, Visawa Park, Satara,
              Maharashtra, 415002
            </p>
            <a href={Brochure} target="_blank" rel="noopener noreferrer">
              <button className="text-uppercase text-center bgGreen btn-orange text-white fw-bold">
                <i className="fa-solid fa-download text-white"></i> Download Brochure
              </button>
            </a>
          </Col>
          <Col lg={3}>
            <h6 className="py-3">Company</h6>
            <div className="py-1">
              <Link to="/About" className="text-decoration-none lightTxt">
                About us
              </Link>
            </div>
            <div className="py-1">
              <Link to="/CustomerReview" className="text-decoration-none lightTxt">
                Customer Review
              </Link>
            </div>
            <div className="py-1">
              <Link to="/contact" className="text-decoration-none lightTxt">
                Contact Us
              </Link>
            </div>
          </Col>
          <Col lg={3}>
            <h6 className="py-3">Project</h6>
            <div className="py-1">
              <Link to="/OurProjects" className="text-decoration-none lightTxt">
                Our Projects
              </Link>
            </div>
            <div className="py-1">
              <Link to="/Gallery" className="text-decoration-none lightTxt">
                Gallery
              </Link>
            </div>
            <div className="py-1">
              <Link to="/AdvanceHydroponicFarmingTechnology" className="text-decoration-none lightTxt">
                Learn Hydroponics
              </Link>
            </div>
          </Col>
          <Col lg={3}>
            <h6 className="py-3">Services</h6>
            <div className="py-1">
              <Link to="/nvphAframe" className="text-decoration-none lightTxt">
                Naturally Ventilated Polyhouse A-Frame
              </Link>
            </div>
            <div className="py-1">
              <Link to="/nvphFlatbed" className="text-decoration-none lightTxt">
                Naturally Ventilated Polyhouse Flatbed
              </Link>
            </div>
            <div className="py-1">
              <Link to="/FanbadAframe" className="text-decoration-none lightTxt">
                Fan & Pad A-Frame
              </Link>
            </div>
            <div className="py-1">
              <Link to="/FanbadFlatbed" className="text-decoration-none lightTxt">
                Fan & Pad Flatbed
              </Link>
            </div>
            <div className="py-1">
              <Link to="/IndoorVertical" className="text-decoration-none lightTxt">
                Indoor Vertical N.F.T.
              </Link>
            </div>
          </Col>
        </Row>

        <Row>
          {columnLinks.map((links, colIndex) => (
            <Col lg={3} md={6} sm={12} key={colIndex}>
              <div className="text-center">
                {links.map((city, index) => (
                  <div key={index} className="py-1 text-justify">
                    <Link to="/contact" className="text-decoration-none lightTxt">
                      Commercial Hydroponic Farm at {city}
                    </Link>
                  </div>
                ))}
                {isVisible[colIndex] && additionalColumns[colIndex] && (
                  <>
                    {additionalColumns[colIndex].map((city, index) => (
                      <div key={index} className="py-1 text-justify">
                        <Link to="/contact" className="text-decoration-none lightTxt">
                          Commercial Hydroponic Farm at {city}
                        </Link>
                      </div>
                    ))}
                    <button onClick={() => toggleVisibility(colIndex)} className="btn btn-light mt-3">
                      View Less
                    </button>
                  </>
                )}
                {!isVisible[colIndex] && additionalColumns[colIndex] && (
                  <button onClick={() => toggleVisibility(colIndex)} className="btn btn-light mt-3">
                    View More
                  </button>
                )}
              </div>
            </Col>
          ))}
        </Row>

        <Row className="pt-100">
          <Col className="text-center m-10">
            <p>GSTIN: 27AACHR0745D1ZK</p>
            <p>CIN :U74900PN2014PTC152556</p>
            <h6>Royal Green House Construction (I) Pvt. Ltd.</h6>
            <p>
              #S2/A, Sai Ratna Building, Survey No 517/5, Visawa Park, Satara,
              Maharashtra, 415002
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="p-5">
            <Row>
              <Col lg={3} className="px-2 text-center">
                <Link to="/WorkWithus" className="lightTxt text-decoration-none">
                  Work with us
                </Link>
              </Col>
              <Col lg={3} className="px-2 text-center">
                <Link to="/Terms" className="lightTxt text-decoration-none">
                  Terms & Condition
                </Link>
              </Col>
              <Col lg={3} className="px-2 text-center">
                <Link to="/Privacy" className="lightTxt text-decoration-none">
                  Privacy Policy
                </Link>
              </Col>
              <Col lg={3} className="px-2 text-center">
                <Link to="/Refund" className="lightTxt text-decoration-none">
                  Refund Policy
                </Link>
              </Col>
            </Row>
          </Col>
          <Col className="text-center p-5 lightTxt">
            <span>
              Visitors :{" "}
              <a href="https://www.hitwebcounter.com" target="_blank" rel="noopener noreferrer">
                <img
                  src="https://hitwebcounter.com/counter/counter.php?page=8185604&style=0001&nbdigits=5&type=page&initCount=0"
                  title="Free Counter"
                  alt="web counter"
                  border="0"
                />
              </a>{" "}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="text-center p-2">
            <span>
              @{new Date().getFullYear()} Royal Green House Pvt. Ltd., All
              rights Reserved
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
