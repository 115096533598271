import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.png";
import CardImg2 from "../../Assets/step2.png";
import CardImg3 from "../../Assets/step3.png";
import CardImg4 from "../../Assets/step4.png";
import Enquiry from "../../Components/Enquiry";
import Accordion from "react-bootstrap/Accordion";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import TunnelShedHouse from "../../Assets/gallery/TunnelShedHouse.png";
function TunnelShadeHouse() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              {" "}
              Tunnel Shadenet House
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
              Tunnel Shadenet House
              </a>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="m-5 mb-2">
            <h6 className="text-green m-2">
            Tunnel Shadenet House
            </h6>
            <h2 className="m-2"> Tunnel Shadenet House</h2>
            {/* <p className='text-muted m-2'>Naturally Ventilated Greenhouse with 10 % Top Ventilation.
                            50% or 75% Black Shadenet will be provided above polyhouse at 1.0 mtr distance.Top shadenet is motorized operated.Most suitable for Floriculture & Vegetable cultivation in Hot climate region.</p>
                        <p className='text-muted m-2'>
                            Royal Green House is a most recognised brand in the Indian Hydroponics Industry. We install and set up Hydroponics Project Development that allows you to avail the benefits of a completely automated farm with year round production.

                        </p> */}
            <Row>
              <Col lg={6} className="text-justify">
                <p className="text-muted m-2">
                  A Tunnel Net House is an innovative agricultural structure
                  designed to protect crops from environmental challenges while
                  enhancing growing conditions. Ideal for a variety of crops
                  including vegetables, fruits, and ornamental plants, our
                  Tunnel Net Houses ensure your produce thrives with optimal
                  protection and care.
                </p>
                <p className="text-muted m-2">
                  Key features include durable construction using high-quality
                  GI pipes and UV-stabilized netting, designed to withstand
                  various climatic conditions. The netting material allows
                  sufficient air circulation, reducing the risk of fungal
                  diseases and ensuring a healthier growing environment. The
                  fine mesh netting effectively keeps out pests and birds,
                  minimizing crop damage and improving overall yield.
                  Additionally, the structure is designed to diffuse light,
                  reducing the intensity of direct sunlight and preventing crop
                  stress.
                </p>
                <p className="text-muted m-2">
                  We offer customization options such as different sizes and
                  structures to fit your specific requirements, ensuring that
                  the design is perfectly suited to your farm layout. You can
                  also choose from a range of netting materials with varying
                  levels of shade and protection, tailored to the needs of
                  different crops. Optional accessories like irrigation systems
                  and side roll-ups can further enhance your Tunnel Net House.
                </p>
                <p className="text-muted m-2">
                  Applications include growing a wide variety of vegetables in a
                  controlled environment, ensuring consistent quality and yield.
                  The structure is also ideal for protecting delicate fruits
                  like berries from environmental stressors, enabling higher
                  productivity.
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img
                  src={TunnelShedHouse}
                  alt="vine"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="mx-5">
            <h3 className="m-2">Features</h3>
            <ul>
              <li className="text-muted m-2">
                Optimum plant stress will be maintain easily.
              </li>
              <li className="text-muted m-2">
                Sun light will be controlled on LUX basis.
              </li>
              <li className="text-muted m-2">
                Temperature will be reduced upto 6 to 8 Deg.
              </li>
              <li className="text-muted m-2">
                Top shadenet is motorized operated.
              </li>
              <li className="text-muted m-2">Gutter Height: 4.5 Mtr</li>
              <li className="text-muted m-2">Structure Grid: 8 Mtr x 4 Mtr</li>
              <li className="text-muted m-2">Top Ventilation: 0.85 Mtr</li>
              <li className="text-muted m-2">Side Ventilation: 2.7 Mtr</li>
              <li className="text-muted m-2">Total Height: 6.5 Mtr</li>
              <li className="text-muted m-2">
                Most suitable for Floriculture & Vegetable cultivation in Hot
                climate region.
              </li>

              <li className="text-muted m-2">
                50% or 75% Black Shadenet will be provided above polyhouse at
                1.0 mtr distance.
              </li>
            </ul>
          </Col>
        </Row>
        

      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default TunnelShadeHouse;
