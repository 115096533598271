import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import manimg from "../../Assets/f2.jpg";
import { Link } from "react-router-dom";
import '../../App.css';

function AgreeServicess() {
  return (
    <>
      <Container fluid>
        <Row className="p-5">
          <Col lg={12} className="pt-5 ">
            <Row>
              <Col lg={6}>
                <Card border="light">
                  <i class="fa-solid fa-money-bill-wave text-green fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title>Grow Your Money</Card.Title>
                    <Card.Text>
                      Grow your money with a help of hydroponic farming with
                      Royal Green House Construction Pvt Ltd.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light">
                  <i class="fa-solid fa-user-ninja  text-green fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title>Highest Level of Customer Service</Card.Title>
                    <Card.Text>
                      5-Star Customer Service where Treating your customer as a
                      human being, and not just as a number
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light">
                  <i class="fa-solid fa-layer-group text-green fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title>
                      Bigest Community of hydroponic Farmers
                    </Card.Title>
                    <Card.Text>
                      Hydroponics is a type of agriculture or gardening method
                      that doesn’t use soil.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light">
                  <i class="fa-solid fa-calendar-days text-green fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title>Hydroponic Farm Consulting Services</Card.Title>
                    <Card.Text>
                      Planning and procuring high-quality seedling for optimum
                      harvest outputs and yields.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light">
                  <i class="fa-solid fa-users text-green fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title>Certified Team</Card.Title>
                    <Card.Text>
                      Our team members have years of experience and can design
                      any complete hydroponic system in short time.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light">
                  <Card.Body className=" text-white p-4 mx-3 rounded">
                    <Card.Title>
                      Team that <br /> Delivers great Result
                    </Card.Title>
                    <Card.Text>
                      Benefiting from nearly 25 <br /> years of collective{" "}
                      <br /> experiene.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="bgyellow">
          <Col
            sm={6}
            lg={8}
            className="p-3 d-flex align-items-center justify-content-center"
          >
            <div className=" p-3">
              <h5 className="text-yellow fw-bold m-2 p-2">
                Make Tomorrow Better Than Today.
              </h5>
              <h2 className=" text-light fw-bold m-2 p-2">
                We are providing specialised knowledge and the best
                Agri-services.
              </h2>
              <p className=" text-light m-2 p-2">
                Every day, we have more people on planet Earth — and less
                topsoil, water, and land with which to grow food.
              </p>
              <p className=" text-light m-2 p-2">
                Which leaves us with the question: how will we sustainably feed
                future generations of humans? Some researchers believe that
                hydroponics — a method of growing food with water, nutrients,
                and light, but no soil — could be part of the solution.
              </p>
              <Link to="/Contact" className="btn btn-success m-3">
                {" "}
                <i class="fa-solid fa-circle-arrow-right fa-beat"> </i> Explore
                All Features!
              </Link>
            </div>
          </Col>
          <Col
            sm={6}
            lg={4}
            className="p-4   d-flex align-items-center justify-content-center"
          >
            <img src={manimg} className="img-fluid rounded" alt="hydroponic farm" />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AgreeServicess;
