import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
function Privacy() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Privacy Policy
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                Privacy Policy
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-muted m-5">
            <h6>SECTION 1 – WHAT DO WE DO WITH YOUR INFORMATION?</h6>
            <br />

            <p>
              When you purchase something from our store, as part of the buying
              and selling process, we collect the personal information you give
              us such as your name, address and email address.
            </p>

            <p>
              When you browse our store, we also automatically receive your
              computer’s internet protocol (IP) address in order to provide us
              with information that helps us learn about your browser and
              operating system.
            </p>

            <p>
              Email marketing (if applicable): With your permission, we may send
              you emails about our store, new products and other updates.
            </p>
            <br />

            <h6>SECTION 2 – CONSENT</h6>
            <br />

            <p>How do you get my consent?</p>

            <p>
              When you provide us with personal information to complete a
              transaction, verify your credit card, place an order, arrange for
              a delivery or return a purchase, we imply that you consent to our
              collecting it and using it for that specific reason only.
            </p>

            <p>
              If we ask for your personal information for a secondary reason,
              like marketing, we will either ask you directly for your expressed
              consent, or provide you with an opportunity to say no.
            </p>

            <p>How do I withdraw my consent?</p>

            <p>
              If after you opt-in, you change your mind, you may withdraw your
              consent for us to contact you, for the continued collection, use
              or disclosure of your information, at anytime, by contacting us at
              contact@royalgreenhouse.co.in or mailing us at:
            </p>

            <p>Royal Green House Pvt. Ltd,</p>

            <p>
              #F2/B, Sairatn Building, Survey No 517/5, Visava Naka, Satara,
              Maharashtra, 415002
            </p>
            <br />

            <h6>SECTION 3 – DISCLOSURE</h6>
            <br />

            <p>
              We may disclose your personal information if we are required by
              law to do so or if you violate our Terms of Service.
            </p>
            <br />

            <h6>SECTION 4 – THIRD-PARTY SERVICES</h6>
            <br />

            <p>
              In general, the third-party providers used by us will only
              collect, use and disclose your information to the extent necessary
              to allow them to perform the services they provide to us.
            </p>

            <p>
              However, certain third-party service providers, such as payment
              gateways and other payment transaction processors, have their own
              privacy policies in respect to the information we are required to
              provide to them for your purchase-related transactions.
            </p>

            <p>
              For these providers, we recommend that you read their privacy
              policies so you can understand the manner in which your personal
              information will be handled by these providers.
            </p>

            <p>
              In particular, remember that certain providers may be located in
              or have facilities that are located a different jurisdiction than
              either you or us. So if you elect to proceed with a transaction
              that involves the services of a third-party service provider, then
              your information may become subject to the laws of the
              jurisdiction(s) in which that service provider or its facilities
              are located.
            </p>

            <p>
              As an example, if you are located in China and your transaction is
              processed by a payment gateway located in India, then your
              personal information used in completing that transaction may be
              subject to disclosure under Indian legislation.
            </p>

            <p>
              Once you leave our store’s website or are redirected to a
              third-party website or application, you are no longer governed by
              this Privacy Policy or our website’s Terms of Service.
            </p>

            <p>Links</p>

            <p>
              When you click on links on our store, they may direct you away
              from our site. We are not responsible for the privacy practices of
              other sites and encourage you to read their privacy statements.
            </p>
            <br />

            <h6>SECTION 5 – SECURITY</h6>
            <br />

            <p>
              To protect your personal information, we take reasonable
              precautions and follow industry best practices to make sure it is
              not inappropriately lost, misused, accessed, disclosed, altered or
              destroyed.
            </p>

            <p>
              If you provide us with your credit card information, the
              information is encrypted using secure socket layer technology
              (SSL) and stored with a AES-256 encryption. Although no method of
              transmission over the Internet or electronic storage is 100%
              secure, we follow all PCI-DSS requirements and implement
              additional generally accepted industry standards.
            </p>
            <br />

            <h6>SECTION 6 – COOKIES</h6>
            <br />

            <p>
              Here is a list of cookies that we use. We’ve listed them here so
              you that you can choose if you want to opt-out of cookies or not.
            </p>

            <p>
              {" "}
              _session_id, unique token, sessional, Allows us to store
              information about your session (referrer, landing page, etc).
            </p>

            <p>
              no data held, Persistent for 30 minutes from the last visit, Used
              by our website provider’s internal stats tracker to record the
              number of visits
            </p>

            <p>
              no data held, expires midnight (relative to the visitor) of the
              next day, Counts the number of visits to a store by a single
              customer.
            </p>

            <p>
              cart, unique token, persistent for 2 weeks, Stores information
              about the contents of your cart.
            </p>

            <p> _secure_session_id, unique token, sessional.</p>

            <p>
              {" "}
              storefront_digest, unique token, indefinite If the shop has a
              password, this is used to determine if the current visitor has
              access.
            </p>
            <br />

            <h6>SECTION 7 – AGE OF CONSENT</h6>
            <br />

            <p>
              By using this site, you represent that you are at least the age of
              majority in your state or province of residence, or that you are
              the age of majority in your state or province of residence and you
              have given us your consent to allow any of your minor dependents
              to use this site.
            </p>
            <br />

            <h6>SECTION 8 – CHANGES TO THIS PRIVACY POLICY</h6>
            <br />

            <p>
              We reserve the right to modify this privacy policy at any time, so
              please review it frequently. Changes and clarifications will take
              effect immediately upon their posting on the website. If we make
              material changes to this policy, we will notify you here that it
              has been updated, so that you are aware of what information we
              collect, how we use it, and under what circumstances, if any, we
              use and/or disclose it.
            </p>

            <p>
              If our store is acquired or merged with another company, your
              information may be transferred to the new owners so that we may
              continue to sell products to you.
            </p>
            <br />

            <h6>QUESTIONS AND CONTACT INFORMATION</h6>
            <br />

            <p>
              If you would like to: access, correct, amend or delete any
              personal information we have about you, register a complaint, or
              simply want more information contact our Privacy Compliance
              Officer at contact@royalgreenhouse.co.in
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Privacy;
