import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.png";
import CardImg2 from "../../Assets/step2.png";
import CardImg3 from "../../Assets/step3.png";
import CardImg4 from "../../Assets/step4.png";
import Enquiry from "../../Components/Enquiry";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import DwcImage from "../../Assets/DwcImage.jpeg";
import "../../App.css";
import { Link } from "react-router-dom";

function DWC() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h2 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              DWC System
            </h2>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">DWC System</a>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="m-5 mb-2">
            <h6 className="text-green m-2">DWC</h6>
            <h2 className="m-2">DWC System</h2>

            <Row>
              <Col lg={6}>
                <h1 className="text-muted m-2">
                  What is Deep Water Culture (DWC) in Hydroponics?
                </h1>
                <p className="text-muted m-2 text-justify">
                  If you're new to <span className="fw-bold">hydroponics</span>,
                  the term{" "}
                  <span className="fw-bold">Deep Water Culture (DWC)</span> may
                  seem complex, but it's one of the{" "}
                  <span className="fw-bold">
                    most effective and simple hydroponic systems
                  </span>{" "}
                  for growing plants. In a{" "}
                  <span className="fw-bold">DWC system</span>, plant roots are
                  submerged in a{" "}
                  <span className="fw-bold">nutrient-rich, oxygenated</span>{" "}
                  water solution, which results in{" "}
                  <span className="fw-bold">accelerated plant growth</span>{" "}
                  compared to traditional soil farming. Since plants in a{" "}
                  <span className="fw-bold">DWC setup</span> have constant
                  access to nutrients and oxygen, their growth is faster and
                  more robust. Additionally, the{" "}
                  <span className="fw-bold">cost per plant</span> in a DWC
                  system is often lower, making it an ideal method for beginners
                  and experienced growers alike.
                </p>

                <h2 className="text-muted m-2 mt-3">
                  Why Choose Royal Green House’s DWC System?
                </h2>
                <p className="text-muted m-2 text-justify">
                  At <span className="fw-bold">Royal Green House</span>, our
                  <span className="fw-bold"> Deep Water Culture (DWC) systems</span>{" "}
                  provide continuous submersion of plant roots in nutrient-rich,
                  well-oxygenated water. This unique design ensures that your
                  plants have constant access to{" "}
                  <span className="fw-bold">vital nutrients</span> without the
                  need for frequent watering. With{" "}
                  <span className="fw-bold">minimal maintenance</span>, our DWC
                  system fosters{" "}
                  <span className="fw-bold">rapid, healthy plant growth</span>,
                  making it ideal for anyone—from hobbyists to commercial
                  hydroponic farmers.
                </p>

                <h2 className="text-muted m-2 mt-3">Components of a DWC System</h2>
                <p className="text-muted m-2">
                  Setting up a{" "}
                  <span className="fw-bold">Deep Water Culture system</span>{" "}
                  involves a few basic components:
                  <ul>
                    <li>
                      A <span className="fw-bold">reservoir</span> that holds
                      the nutrient solution.
                    </li>
                    <li>
                      An <span className="fw-bold">air pump</span> and{" "}
                      <span className="fw-bold">airstone</span> to oxygenate the
                      solution.
                    </li>
                    <li>
                      <span className="fw-bold">Net pots</span> to hold the
                      plants.
                    </li>
                    <li>
                      <span className="fw-bold">Hydroponic nutrients</span> to
                      ensure plant growth.
                    </li>
                  </ul>
                </p>
                <p className="text-muted m-2 text-justify">
                  For larger-scale growers, we also offer{" "}
                  <span className="fw-bold">
                    Recirculating Deep Water Culture (RDWC)
                  </span>{" "}
                  systems, which allow multiple plants to share a centralized
                  nutrient solution. This enhances efficiency, ensuring
                  consistent nutrient and oxygen distribution for larger
                  commercial operations, with minimal ongoing maintenance.
                </p>

                <h2 className="text-muted m-2 mt-3">
                  Benefits of Using DWC Systems
                </h2>
                <ul>
                  <li>
                    <span className="fw-bold">Faster Growth:</span> DWC systems
                    promote faster growth as plant roots have direct access to
                    nutrients and oxygen 24/7.
                  </li>
                  <li>
                    <span className="fw-bold">Cost-Effective:</span> DWC setups
                    are more affordable than other hydroponic methods, both in
                    terms of initial setup and maintenance.
                  </li>
                  <li>
                    <span className="fw-bold">Low Maintenance:</span> The simple
                    design of a DWC system means fewer parts to manage, making
                    it ideal for beginners.
                  </li>
                  <li>
                    <span className="fw-bold">Scalability:</span> With RDWC
                    systems, you can easily scale your operation for commercial
                    hydroponics, ensuring high yields with low input costs.
                  </li>
                </ul>

                <p className="text-muted m-2 text-justify">
                  Whether you're just beginning your journey into{" "}
                  <span className="fw-bold">hydroponic farming</span> or are
                  looking to scale up your operations,{" "}
                  <span className="fw-bold">
                    Royal Green House's DWC and RDWC systems
                  </span>{" "}
                  are perfect for optimizing plant growth and maximizing yield.
                  Contact us today to learn more about how we can help you
                  implement a thriving{" "}
                  <span className="fw-bold">Deep Water Culture system</span>.
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img
                  src={DwcImage}
                  alt="DWC System Image"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <h3 className="p-5 text-center bgyellow">
            <Link
              to="https://wa.me/+919960991166"
              className="text-warning"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us for a detailed quotation.
              <sub className="text-white">Click here</sub>
            </Link>
          </h3>
        </Row>

        <Row>
          <Col lg={12} className=" p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and an A-frame system, now we
                      can grow 70% more food in the same area.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow Anywhere You Want
                    </Card.Title>
                    <Card.Text>
                      We can now grow food anywhere with the help of water and
                      protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-circle-nodes text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Precision Farming
                    </Card.Title>
                    <Card.Text>
                      By using modern-day technology and sensors, farming is now
                      more accurate and efficient.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-filter-circle-dollar text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Easy And Fast ROI
                    </Card.Title>
                    <Card.Text>
                      The investment in a DWC system is quickly recouped due to
                      its low maintenance and high yield.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <TestimonialsSlider />
        </Row>

        <Row>
          <Enquiry />
        </Row>
      </Container>
    </>
  );
}

export default DWC;
