import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.png";
import CardImg2 from "../../Assets/step2.png";
import CardImg3 from "../../Assets/step3.png";
import CardImg4 from "../../Assets/step4.png";
import Enquiry from "../../Components/Enquiry";
import Accordion from "react-bootstrap/Accordion";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import DftAframe from "../../Assets/DftAframe.png";
import '../../App.css';
import { Link } from 'react-router-dom';

import NftACollage from "../../Assets/NftACollage.png";     

function DFTAFrame() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              DFT A-Frame
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">DFT A-Frame</a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="m-5 mb-2">
            <h6 className="text-green m-2">DFT A-Frame</h6>
            <h2 className="m-2">DFT A-Frame System</h2>
            {/* <p className='text-muted m-2'>Naturally ventilated polyhouse with flatbed is outdoor hydroponic farm setup. The outdoor hydroponics farm method is a type of controlled environment agriculture which allows you to grow various type of leafy greens and vine crops much densely then any other outdoor method, where you can save water use by up to 90%, requires 80 to 95 percent less area, and produce all year round.
                        </p>
                        <p className='text-muted m-2'>
                            Royal Green House is a most recognised brand in the Indian Hydroponics Industry. We install and set up Hydroponics Project Development that allows you to avail the benefits of a completely automated farm with year round production.
                        </p> */}
            <Row>
              <Col lg={6}>
                <p className="text-muted m-2 text-justify">
                  {" "}
                  The DFT (Deep Flow Technique) A-frame system is a versatile
                  hydroponic solution designed to optimize crop production in a
                  compact, vertical layout. By providing a deeper flow of
                  nutrients, the DFT system allows plant roots to absorb more
                  water and nutrients, promoting robust and healthy growth. The
                  A-frame design efficiently utilizes space by supporting
                  multiple layers of plants, making it ideal for high-density
                  cultivation. Royal Green House recommends using 5 or a maximum
                  of 6 layers for optimal growth and ease of management. This
                  deeper nutrient flow enhances plant resilience and
                  adaptability, making the system suitable for a wide variety of
                  crops.
                </p>
                <p className="text-muted m-2 text-justify">
                  This system is particularly advantageous for growers aiming
                  for year-round production, as it operates independently of
                  external weather conditions. The DFT A-frame setup includes a
                  sturdy stand with a protective coating, ensuring durability
                  and stability over time. This reliability makes it an
                  excellent choice for both commercial growers and hobbyists who
                  want to maximize their yields in a controlled environment.
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img
                  src={DftAframe}
                  alt="vine"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        
<Row>
          <h3 className="p-5 text-center bgyellow">
            <Link
              to="https://wa.me/+919960991166"
              className="text-warning"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us for a detailed quotation.
              <sub className="text-white">Click here</sub>
            </Link>
          </h3>
        </Row>


        <Row>
          <Col lg={12} className=" p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and A frame system now we can
                      grow 70 percent more food in the same area.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow anywhere you want
                    </Card.Title>
                    <Card.Text>
                      We can now grow our food anywhere with help of just water
                      and protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-hand-holding-droplet text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">Less water</Card.Title>
                    <Card.Text>
                      Now grow crops on 90% less water compared to field crops.
                      This farming is possible at any location even in water
                      scared zones.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-bolt text-green  fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      High value cash crops
                    </Card.Title>
                    <Card.Text>
                      Not only our daily crops but we can grow super-crops or
                      very high value cash crops with controlled environment.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h6 className="text-green m-2 text-center">
              Our Process In Some Steps
            </h6>
            <h4 className="m-2 text-center fw-bold">
              Building Commercially Successful Hydroponic Farm
            </h4>
          </Col>
        </Row>
        <Row className="p-2 m-3">
          {/* second cards */}
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg1}  alt="Step One" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Site Feasibility
                </Card.Title>
                <Card.Text className=" text-white p-1">
                  We help you identify a site location to set up our hydroponic
                  project. We consider locality, levelling of land, examine
                  electricity and water also gather basic information of
                  surrounding that can help us test feasibility.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg2}  alt="Step Two" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Ideal Farm Construction
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once financial feasibility is analysed we start working on the
                  Construction of the Project. Based on site feasibility and
                  market research we choose between NVPH or Fan-Pad system and
                  also hydroponic system between NFT or Dutch Bucket.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg3}  alt="Step Three" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Farm Management{" "}
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once construction is completed, we work on automating the farm
                  using IoT devices as well as integrating with farm management.
                  At this stage, we also start training your field team to make
                  sure we continuously grow high quality produce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg4}  alt="Step Four" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Contract & Markerting Your Product
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once our field team is ready and the complete farm is
                  automated, Our team of agronomists, sales and planning starts
                  working on the contract and set a selling mechanism for your
                  farm, with a schedule of crops.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default DFTAFrame;
