import React from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import NavDropdown from "react-bootstrap/NavDropdown";
import royalgreenlogo from "../../Assets/royalgreenlogo.png";
import "./header.module.css";
import "../../App.css";
import GoogleTranslate from "../GoogleTranslate";

function Header() {
  const handleLanguageChange = (langCode) => {
    window.location.href =
      "https://translate.google.com/translate?sl=auto&tl=" +
      langCode +
      "&u=" +
      window.location.href;
  };

  return (
    <>
      <div className="navbar-container">
        <Navbar expand="xl" className="navbar-light sticky-top">
          <Container fluid className="px-4">
            <Navbar.Brand href="#home" className="text-dark">
              <img src={royalgreenlogo} width="150" alt="Royal Green Logo" />
            </Navbar.Brand>

            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="text-dark"
            />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                <Nav.Link
                  as={Link}
                  to="/"
                  className="text-uppercase text-center mx-1 fw-bold"
                >
                  Home
                </Nav.Link>
                <NavDropdown
                  title="Company"
                  id="company-nav-dropdown"
                  className="text-uppercase text-center mx-1 fw-bold"
                >
                  <NavDropdown.Item as={Link} to="/about">
                    About Us
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/customerReview">
                    Customer Review
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Services"
                  id="services-nav-dropdown"
                  className="text-uppercase text-center mx-1 fw-bold"
                >
                  <NavDropdown
                    title="Hydroponic Setup"
                    id="hydroponic-setup-nav-dropdown"
                    className="text-uppercase mx-1 fw-bold"
                  >
                    <NavDropdown.Item as={Link} to="/nftAframe">
                      NFT A-Frame System
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/NftFlatbed">
                      NFT Flatbed System
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/DFTAFrame">
                      DFT A-Frame System
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/DWC">
                      DWC System
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/DutchBucket">
                      Dutch Bucket/Bato Bucket System
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/Grow_Slab">
                      Grow Slab System
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/Grow_bag">
                      Grow Bag System
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown
                    title="Polyhouse Setups"
                    id="polyhouse-setups-nav-dropdown"
                    className="text-uppercase mx-1 fw-bold"
                  >
                    <NavDropdown.Item as={Link} to="/Nvph">
                      Naturally Ventilated Polyhouse
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/ClimateControlled">
                      Climate Control Polyhouse (Fan & Pad)
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item as={Link} to="/Polycarbonet">
                      Polycarbonet Polyhouse Greenhouse
                    </NavDropdown.Item> */}
                  </NavDropdown>

                  <NavDropdown
                    title="Shade Net House Setups"
                    id="shade-net-house-nav-dropdown"
                    className="text-uppercase mx-1 fw-bold"
                  >
                    <NavDropdown.Item as={Link} to="/Flat_Shade_Net_House">
                      Flat Shade Net House
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/TunnelShadeHouse">
                      Tunnel Shade Net House
                    </NavDropdown.Item>
                  </NavDropdown>

                  <Nav.Link
                    as={Link}
                    to="/LowcostGreenhouse"
                    className="text-uppercase mx-1 fw-bold"
                  >
                    Low Cost Greenhouse Setup
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/RainProtectedWireRopeNetHouse"
                    className="text-uppercase mx-1 fw-bold"
                  >
                    Rain Protected Wire Rope Net House
                  </Nav.Link>
                </NavDropdown>

                <NavDropdown
                  title="Products"
                  id="products-nav-dropdown"
                  className="text-uppercase text-center mx-1 fw-bold"
                >
                  <NavDropdown.Item as={Link} to="/LeafyGreens">
                    Leafy Greens
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/VineCrops">
                    Vine Crops
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/Lettuces">
                    Lettuces
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/FreshHerbs">
                    Fresh Herbs
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item as={Link} to="/Horticulture">
                    Horticulture
                  </NavDropdown.Item> */}
                  <NavDropdown.Item as={Link} to="/Floriculture">
                    Floriculture
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Projects"
                  id="projects-nav-dropdown"
                  className="text-uppercase text-center mx-1 fw-bold"
                >
                  <NavDropdown.Item as={Link} to="/ourProjects">
                    Our Projects
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/gallery">
                    Gallery
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/videos">
                    Videos
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav
                  as={Link}
                  to="https://www.ahft.co.in/"
                  className="text-uppercase text-center mx-1 fw-bold"
                  style={{
                    color: "green",
                    textDecoration: "none",
                    marginLeft: "10px",
                    padding: "10px 10px",
                  }}
                  target="_blank"
                >
                  Enroll Hydroponic Course
                </Nav>

                <Nav.Link
                  as={Link}
                  to="/Blog"
                  className="text-uppercase text-center mx-1 fw-bold"
                >
                  Blog
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/contact"
                  className="text-uppercase text-center mx-1 fw-bold"
                >
                  <a
                    href="tel:+919960991166"
                    className="text-dark fw-bold text-decoration-none"
                  >
                    Contact Us
                  </a>
                </Nav.Link>

                <a
                  href="https://wa.me/+919960991166"
                  target="_blank"
                  className="text-center mx-1 fw-bold"
                >
                  <i className="fa-brands fa-whatsapp fs-1 mx-1 text-green rotate"></i>
                </a>

                
              </Nav>

              
              <NavDropdown
                  title="Select Language"
             
                  className="text-uppercase mx-1 fw-bold"
                >



                  
                  <div className="px-3 py-2">
                    <GoogleTranslate />
                  </div>

                  
                  </NavDropdown>
                  {/* <ul><li title="light mode">
                    <a className="setColor light active" data theme="light">
                      <i className="fal fa-lightbulb-on"></i>
                    </a>
                    </li>
                    <li title="dark mode">
                      <a className="setColor dark" data-theme="dark">
                        <i className="fas fa-moon"></i>
                      </a>
                      </li></ul> */}
              
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default Header;



