import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import img1 from "../../Assets/clients/c/client11.png";
import img2 from "../../Assets/clients/c/client12.png";
import img3 from "../../Assets/clients/c/client14.png";
import img4 from "../../Assets/clients/c/client13.png";
import img5 from "../../Assets/clients/c/client15.png";
import img6 from "../../Assets/clients/c/client16.png";
import img7 from "../../Assets/clients/c/client17.png";
import img8 from "../../Assets/clients/c/client18.png";
import img9 from "../../Assets/clients/c/client19.png";
import img10 from "../../Assets/clients/c/client20.png";
import Carousel from "react-bootstrap/Carousel";
import "../../App.css";

function CustomSlider() {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4 className="text-center text-danger mt-5">
              SOME OF OUR CLIENTS
            </h4>
          </Col>
        </Row>
      </Container>

      <Carousel
        variant="dark"
        className="pb-4 m-2"
        interval={1500}
        pause={false}
      >
        <Carousel.Item className="p-lg-4">
          <div className="text-center">
            <img
              src={img1}
              style={{ width: "200px" }}
              className="mx-auto px-2 pt-3"
              alt="client-img"
            />
            <img
              src={img2}
              style={{ width: "200px" }}
              className="mx-auto px-2 pt-3"
              alt="client-img"
            />
            <img
              src={img3}
              style={{ width: "200px" }}
              className="mx-auto px-2 pt-3"
              alt="client-img"
            />
            <img
              src={img4}
              style={{ width: "200px" }}
              className="mx-auto px-2 pt-3"
              alt="client-img"
            />
            <img
              src={img5}
              style={{ width: "200px" }}
              className="mx-auto px-2 pt-3"
              alt="client-img"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item className="p-lg-4">
          <div className="text-center">
            <img
              src={img7}
              style={{ width: "200px" }}
              className="mx-auto px-2 pt-3"
              alt="client-img"
            />
            <img
              src={img8}
              style={{ width: "200px" }}
              className="mx-auto px-2 pt-3"
              alt="client-img"
            />
            <img
              src={img9}
              style={{ width: "200px" }}
              className="mx-auto px-2 pt-3"
              alt="client-img"
            />
            <img
              src={img10}
              style={{ width: "200px" }}
              className="mx-auto px-2 pt-3"
              alt="client-img"
            />
            <img
              src={img6}
              style={{ width: "200px" }}
              className="mx-auto px-2 pt-3"
              alt="client-img"
            />
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default CustomSlider;
