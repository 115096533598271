import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.png";
import CardImg2 from "../../Assets/step2.png";
import CardImg3 from "../../Assets/step3.png";
import CardImg4 from "../../Assets/step4.png";
import Enquiry from "../../Components/Enquiry";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import Growslab1 from "../../Assets/Growslab1.png";
import "../../App.css";
import { Link } from "react-router-dom";

function Grow_Slab() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Grow Slab System
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                Grow Slab System
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="m-5 mb-2">
            <h6 className="text-green m-2">Grow Slab System</h6>
            <h2 className="m-2">Grow Slab System</h2>
            <Row>
              <Col lg={6}>
                <p className="text-muted m-2 text-justify">
                  <h2 className="text-muted m-2">
                    What is a Grow Slab System?
                  </h2>{" "}
                  A <span className="fw-bold">Grow Slab System</span> is an
                  advanced <span className="fw-bold">hydroponic solution</span>
                  specifically designed for{" "}
                  <span className="fw-bold">indoor gardening</span>. This system
                  is ideal for cultivating a variety of plants in a controlled
                  environment, allowing growers to maximize yields and manage
                  plant health effectively. The system utilizes flat,
                  rectangular <span className="fw-bold">grow slabs</span> filled
                  with a growing medium, such as rock wool or coco coir, which
                  supports the plant roots. With nutrient-rich water delivered
                  via a drip irrigation system, plants receive a continuous and
                  balanced supply of essential nutrients and water. This ensures
                  robust growth and higher productivity, especially when paired
                  with <span className="fw-bold">LED grow lights</span> that
                  provide the required light spectrum for photosynthesis.
                </p>
                <p className="text-muted m-2 text-justify">
                  <ul>
                    <h2 className="text-muted m-2">
                      Key Features of the Grow Slab System
                    </h2>
                    <li>
                      <span className="fw-bold"> Growing Medium:</span>Grow
                      slabs are filled with moisture-retentive materials like
                      <span className="fw-bold">rock wool</span> or{" "}
                      <span className="fw-bold">coco coir</span>, which provide
                      strong root support and hydration.
                    </li>
                    <li>
                      <span className="fw-bold">Nutrient Delivery:</span> A{" "}
                      <span className="fw-bold">drip irrigation system</span>{" "}
                      feeds water mixed with nutrients directly to the roots,
                      ensuring optimal plant growth.
                    </li>
                    <li>
                      <span className="fw-bold">Lighting:</span> Most grow slab
                      systems utilize{" "}
                      <span className="fw-bold">LED grow lights</span>, which
                      simulate natural sunlight and promote healthy plant
                      development through efficient photosynthesis.
                    </li>
                    <li>
                      <span className="fw-bold">Scalability:</span> The grow
                      slab system is modular and scalable, making it adaptable
                      for a range of garden sizes, from small home setups to
                      large commercial operations.
                    </li>
                    <li>
                      <span className="fw-bold">Environment Control:</span>{" "}
                      Precise control over{" "}
                      <span className="fw-bold">
                        temperature, humidity, and light
                      </span>
                      ensures that plants grow in the most favorable conditions
                      possible.
                    </li>
                  </ul>
                </p>
                <p className="text-muted m-2 text-justify">
                  <ul>
                    <h2 className="text-muted m-2">
                      Benefits of Using a Grow Slab System
                    </h2>
                    <li>
                      <span className="fw-bold">Efficient Use of Space:</span>{" "}
                      The flat, horizontal design of the grow slab system
                      maximizes the growing area while minimizing the physical
                      footprint, making it ideal for space-limited environments.
                    </li>
                    <li>
                      <span className="fw-bold">Water Efficiency:</span> The
                      closed-loop design of the system significantly reduces
                      water usage compared to traditional soil gardening
                      methods, making it a more sustainable option.
                    </li>
                    <li>
                      <span className="fw-bold">High Yield:</span> The
                      controlled growing conditions in a grow slab system lead
                      to faster growth cycles, increasing the potential for
                      higher yields.
                    </li>
                    <li>
                      <span className="fw-bold">Customizability:</span> The
                      system can be easily customized to accommodate different
                      plant types and growth stages, allowing flexibility in
                      managing various crops.
                    </li>
                  </ul>
                </p>
                <p className="text-muted m-2 text-justify">
                  <h2 className="text-muted m-2">
                    Why Choose a Grow Slab System?
                  </h2>
                  The <span className="fw-bold">grow slab system</span> is the
                  perfect solution for gardeners looking to achieve maximum
                  efficiency in their indoor setups. It offers unparalleled
                  control over environmental factors, promotes sustainable water
                  use, and increases yields due to its optimized nutrient
                  delivery and light management. Whether you're growing{" "}
                  <span className="fw-bold">herbs, vegetables</span>, or other
                  indoor plants, the
                  <span className="fw-bold">grow slab system</span> provides a
                  highly adaptable and scalable solution for your gardening
                  needs.
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img src={Growslab1} alt="vine" className="img-fluid rounded" />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <h3 className="p-5 text-center bgyellow">
            <Link
              to="https://wa.me/+919960991166"
              className="text-warning"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us for a detailed quotation.
              <sub className="text-white">Click here</sub>
            </Link>
          </h3>
        </Row>

        <Row>
          <Col lg={12} className=" p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and A frame system now we can
                      grow 70 percent more food in the same area.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow anywhere you want
                    </Card.Title>
                    <Card.Text>
                      We can now grow our food anywhere with help of just water
                      and protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-hand-holding-droplet text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">Less water</Card.Title>
                    <Card.Text>
                      Now grow crops on 90% less water compared to field crops.
                      This farming is possible at any location even in water
                      scared zones.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-bolt text-green  fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      High value cash crops
                    </Card.Title>
                    <Card.Text>
                      Not only our daily crops but we can grow super-crops or
                      very high value cash crops with controlled environment.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h6 className="text-green m-2 text-center">
              Our Process In Some Steps
            </h6>
            <h4 className="m-2 text-center fw-bold">
              Building Commercially Successful Hydroponic Farm
            </h4>
          </Col>
        </Row>
        <Row className="p-2 m-3">
          {/* second cards */}
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg1} alt="Step One" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Site Feasibility
                </Card.Title>
                <Card.Text className=" text-white p-1">
                  We help you identify a site location to set up our hydroponic
                  project. We consider locality, levelling of land, examine
                  electricity and water also gather basic information of
                  surrounding that can help us test feasibility.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg2} alt="Step Two" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Ideal Farm Construction
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once financial feasibility is analysed we start working on the
                  Construction of the Project. Based on site feasibility and
                  market research we choose between NVPH or Fan-Pad system and
                  also hydroponic system between NFT or Dutch Bucket.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg3} alt="Step Three" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Farm Management{" "}
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once construction is completed, we work on automating the farm
                  using IoT devices as well as integrating with farm management.
                  At this stage, we also start training your field team to make
                  sure we continuously grow high quality produce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg4} alt="Step Four" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Contract & Markerting Your Product
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once our field team is ready and the complete farm is
                  automated, Our team of agronomists, sales and planning starts
                  working on the contract and set a selling mechanism for your
                  farm, with a schedule of crops.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default Grow_Slab;
