import React from 'react';
import  { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import './App.css';
import Layout from './Pages/Layout';
import Home from "./Pages/Home";
import About from "./Pages/About";
import Blog from "./Pages/Blog";
import HowToReduceFutureMaintenanceInPolyhouseFarming from "./Pages/Blog/HowToReduceFutureMaintenanceInPolyhouseFarming";
import NVPHBlog from "./Pages/Blog/NVPHBlog";
import FanpadAFrame_Blog from "./Pages/Blog/FanpadAFrame_Blog";
import NVPHFlatbed_Blog from "./Pages/Blog/NVPHFlatbed_Blog";
import ClimateOverview_Blog from "./Pages/Blog/ClimateOverview_Blog";
import Hydroponic_Farming_In_India from "./Pages/Blog/Hydroponic_Farming_In_India";
import Commercial_Hydroponic_Farming from "./Pages/Blog/Commercial_Hydroponic_Farming";
import Polyhouse_Farming_Benefits_and_Setup from "./Pages/Blog/Polyhouse_Farming_Benefits_and_Setup";
import Types_of_Hydroponics_Systems from "./Pages/Blog/Types_of_Hydroponics_Systems";

import Contact from "./Pages/Contact";
import AdvanceHydroponicFarmingTechnology from "./Pages/AdvanceHydroponicFarmingTechnology";
import ExportShop from "./Pages/ExportShop";
import Products from "./Pages/Projects";
import Services from "./Pages/Services";
import NoPage from "./Pages/NoPage";
import Terms from "././Pages/Terms";
import Privacy from "././Pages/Privacy";
import Refund from "././Pages/Refund";
import FmcgProducts from "././Pages/FmcgProducts";
import CustomerReview from "././Pages/CustomerReview";
import NftAframe from './Pages/NftAframe';
import NftFlatbed from './Pages/NftFlatbed';
import DFTAFrame from './Pages/DFTAFrame';
import DWC from './Pages/DWC';
import DutchBucket from './Pages/DutchBucket';
import FanbadAframe from './Pages/FanbadAframe';
import FanbadFlatbed from './Pages/FanbadFlatbed';
import IndoorVertical from './Pages/IndoorVertical';
import OurProjects from './Pages/OurProjects';
import Gallery from './Pages/Gallery';
import WorkWithus from './Pages/WorkWithus';
import Story from './Pages/Story';
import NvphGrowbag from './Pages/NvphGrowbag';
import NvphAframe from './Pages/NvphAframe';
import NvphFlatbed from './Pages/NvphFlatbed';
import NvphDutchBucket from './Pages/NvphDutchBucket';
import RainProtectedWireRopeNetHouse from './Pages/RainProtectedWireRopeNetHouse';
import RequestAQuote from './Pages/RequestAQuote';

import Flat_Shade_Net_House from './Pages/Flat_Shade_Net_House';
import Videos from './Pages/Videos';
import Polycarbonet from './Pages/Polycarbonet';
import ClimateControlled from './Pages/ClimateControlled';
import ShadowHall from './Pages/ShadowHall';
import Login from './Pages/Login';
import Nvph from './Pages/Nvph';
import DosingSystem from './Pages/DosingSystem';
import TempControllers from './Pages/TempControllers';
import LowcostGreenhouse from './Pages/LowcostGreenhouse';
import Dashboard from './Pages/Dashboard';
import FreshHerbs from './Pages/FreshHerbs';
import Lettuces from './Pages/Lettuces';
import VineCrops from './Pages/VineCrops';
import LeafyGreens from './Pages/LeafyGreens';
import Horticulture from './Pages/Horticulture';
import Floriculture from './Pages/Floriculture';
import TawkToChat from './Components/TawkToChat/TawkToChat';
import TunnelShadeHouse from './Pages/TunnelShadeHouse';
import Grow_Slab from './Pages/Grow_Slab';
import Grow_bag from './Pages/Grow_bag';
import Top10_hydroponic_crops_for_india from './Pages/Blog/Top10_hydroponic_crops_for_india';






function App() {
  const [galleryData, setGalleryData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    async function fetchGalleryData() {
      let tempgalleryData = await fetch(
        "//royalgreenhouse.co.in/crud.php?getGalleryData=true"
      )
        .then((response) => response.json())
        .then((response) => response)
        .catch((error) => {
          return "error";
        });
      if (tempgalleryData === "error") {
console.log("php error")
      } else {
        setGalleryData(tempgalleryData);
      }
    }
    fetchGalleryData();

    async function fetchReviewData() {
      let tempReviewData = await fetch(
        "//royalgreenhouse.co.in/crud.php?getReviewData=true"
      )
        .then((response) => response.json())
        .then((response) => response)
        .catch((error) => {
          return "error";
        });
      if (tempReviewData === "error") {
console.log("php error")
      } else {
        setReviewData(tempReviewData);
      }
    }
    fetchReviewData();

  }, []);
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="fmcgProducts" element={<FmcgProducts />} />
        <Route path="NftAframe" element={<NftAframe />} />

        <Route path="NftFlatbed" element={<NftFlatbed />} />
        <Route path="DFTAFrame" element={<DFTAFrame />} />
        <Route path="DWC" element={<DWC />} />
        <Route path="DutchBucket" element={<DutchBucket />} />
        <Route path="fanbadAframe" element={<FanbadAframe />} />
        <Route path="fanbadFlatbed" element={<FanbadFlatbed />} />
        <Route path="indoorVertical" element={<IndoorVertical />} />
        <Route path="nvphgrowbag" element={<NvphGrowbag />} />
        <Route path="NvphAframe" element={<NvphAframe />} />
        <Route path="NvphFlatbed" element={<NvphFlatbed />} />
        <Route path="Grow_Slab" element={<Grow_Slab />} />
        <Route path="Grow_bag" element={<Grow_bag />} />
        <Route path="nvphdutchbucket" element={<NvphDutchBucket />} />
        <Route path="TunnelShadeHouse" element={<TunnelShadeHouse />} />
        <Route path="Flat_Shade_Net_House" element={<Flat_Shade_Net_House />} />
        <Route path="polycarbonet" element={<Polycarbonet />} />
        <Route path="climatecontrolled" element={<ClimateControlled />} />
        <Route path="RainProtectedWireRopeNetHouse" element={<RainProtectedWireRopeNetHouse />} />
        <Route path="shadowhall" element={<ShadowHall />} />

        <Route path="login" element={<Login setLogin={setIsLogin}/>} />
        <Route path="dashboard" element={<Dashboard isLogin={isLogin} galleryData={galleryData} reviewData={reviewData}/>} />

        <Route path="nvph" element={<Nvph />} />
        <Route path="dosingsystem" element={<DosingSystem />} />
        <Route path="tempcontrollers" element={<TempControllers />} />
        <Route path="lowcostgreenhouse" element={<LowcostGreenhouse/>} />

        <Route path="requestaquote" element={<RequestAQuote />} />

        <Route path="OurProjects" element={<OurProjects />} />
        <Route path="gallery" element={<Gallery galleryData={galleryData} />} />
        <Route path="customerReview" element={<CustomerReview reviewData={reviewData}/>} />


        <Route path="blog" element={<Blog />} />
        <Route path="HowToReduceFutureMaintenanceInPolyhouseFarming" element={<HowToReduceFutureMaintenanceInPolyhouseFarming />} />
        <Route path="NVPHBlog" element={<NVPHBlog />} />
        <Route path="FanpadAFrame_Blog" element={<FanpadAFrame_Blog />} />
        <Route path="NVPHFlatbed_Blog" element={<NVPHFlatbed_Blog />} />
        <Route path="ClimateOverview_Blog" element={<ClimateOverview_Blog />} />
        <Route path="Hydroponic_Farming_In_India" element={<Hydroponic_Farming_In_India />} />
        <Route path="Commercial_Hydroponic_Farming" element={<Commercial_Hydroponic_Farming />} />
        <Route path="Polyhouse_Farming_Benefits_and_Setup" element={<Polyhouse_Farming_Benefits_and_Setup />} />
        <Route path="Types_of_Hydroponics_Systems" element={<Types_of_Hydroponics_Systems />} />
        <Route path="Top10_hydroponic_crops_for_india" element={<Top10_hydroponic_crops_for_india />} />

        <Route path="contact" element={<Contact />} />
        <Route path="products" element={<Products />} />
        <Route path="services" element={<Services />} />
        <Route path="WorkWithus" element={<WorkWithus />} />

        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="refund" element={<Refund />} />
        <Route path="advancehydroponicfarmingtechnology" element={<AdvanceHydroponicFarmingTechnology />} />
        <Route path="exportshop" element={<ExportShop />} />
        <Route path="story" element={<Story />} />
        <Route path="videos" element={<Videos />} />
        
        <Route path="freshherbs" element={<FreshHerbs />} />
        <Route path="lettuces" element={<Lettuces />} />
        <Route path="vinecrops" element={<VineCrops />} />
        <Route path="leafygreens" element={<LeafyGreens />} />
        <Route path="Horticulture" element={<Horticulture />} />
        <Route path="Floriculture" element={<Floriculture />} />

        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
   
    <TawkToChat />
   
  </BrowserRouter>
  );
}

export default App;
