import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";

function Refund() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Refund Policy
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                Refund Policy
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-muted m-5">
            <p>
              {" "}
              Hydroponic AdvanceHydroponicFarmingTechnology & Know Hydroponics
            </p>

            <p>
              Our policy lasts 7 days. If 7 days have gone by since your
              purchase, unfortunately we can’t offer you a refund or exchange.
            </p>

            <p>
              While initiating a refund please remember you will be removed from
              the Royal Green House app and all related services of Royal Green
              House.
            </p>

            <p>
              Your subscription will continue till your current subsription
              period after which it will be cancelled and no partial refunds
              will be done.
            </p>
            <p>
              If you have any gift card or coupon code applied to your purchase
              which lowered down the cost, of course, you will get only a refund
              of money which you paid.
            </p>

            <p>
              Any additional transaction charges by payment gateway will be
              deducted from your refund.
            </p>

            <p>
              We do not have any control on time taken by payement gateways to
              refund your money.
            </p>

            <p>
              {" "}
              We store your billing history for 1 year after your refund for
              accounting purposes.
            </p>
            <p>
              If you have any questions or doubts you can write to us at
              support@royalgreenhouse.com
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Refund;
