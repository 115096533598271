import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";

import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/gallery/product1.jpg";
import CardImg2 from "../../Assets/gallery/product2.jpg";
import CardImg3 from "../../Assets/gallery/product4.jpg";
import CardImg4 from "../../Assets/gallery/product3.jpg";
import CardImg5 from "../../Assets/gallery/product5.jpg";
import Thyme from "../../Assets/thyme.png";
import Sage from "../../Assets/sage.png";
import Rosemary from "../../Assets/rosemary.png";
import Parsley from "../../Assets/parsley.png";
import Peppermint from "../../Assets/peppermint.png";
import Coriander from "../../Assets/coriander.png";
import ItalianBasil from "../../Assets/basil.png";

function FreshHerbs() {
  return (
    <Container fluid>
      <Row className="headingimg">
        <Col className="m-5">
          <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
            {" "}
            Products
          </h1>
          <div className="text-white m-4 fw-bold text-center">
            <a href="#" className=" text-white m-3 text-decoration-none">
              Home
            </a>
            <i className="fa-solid fa-circle-arrow-right fa-beat"></i>
            <a className="text-white text-decoration-none m-3">Products</a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="mt-4 pt-4 ">
          <h2 className="text-green m-3 text-center fw-bold">
            Our Fresh Produce
          </h2>
          <h6 className="text-center m-4 fw-bold">
            All our produce is fresh, nutritious and hand-picked. <br /> We are
            dedicated to deliver to you the top-shelf produce.
          </h6>
        </Col>
      </Row>
      
      <Row className="p-2">
        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg1} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Vine Crops
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg2} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Lettuces
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg3} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Leafy Greens
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

       
      </Row>

      <Row className="row justify-content-center">
      <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg4} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Fresh Herbs
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg5} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Floriculture
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col sm={12} className="mt-4 pt-4 ">
          <h1 className="text-green m-3 text-center fw-bold">Fresh Herbs</h1>
          
          
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Italian Basil
              </h2>
              <p className="text-center ">
                The king of herbs with a distinctive fragrance!
                <br />
                Basil belongs to the mint family and is a great flavour
                enhancing ingredient in Italian, Thai and Vietnamese Cuisine.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Packed with Vitamin A and K</li>
                    <li>Contains beta-carotene</li>
                    <li>Rich source of antioxidants</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Supports liver health</li>
                    <li>Good for the skin, prevents ageing</li>
                    <li>Reduces high blood sugar</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={ItalianBasil} alt="img" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img src={Coriander} alt="img" className="img-fluid p-3" />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Coriander
              </h2>
              <p className="text-center ">
                Greener, healthier and better with Coriander!
                <br />
                Coriander is a versatile green that is widely used in raw and
                cooked form as it provides an intense and earthy flavour to the
                food.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Full of antioxidants</li>
                    <li>Good source of Vitamin K, A, C</li>
                    <li>Contains Iron, Calcium and proteins</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Promotes healthy vision</li>
                    <li>Treats liver dysfunctions</li>
                    <li>Reduces inflammation</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Peppermint
              </h2>
              <p className="text-center ">
                Refreshing taste of cool and good health.
                <br />
                Mint is a very versatile herb used in many savoury and sweet
                preparations.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Contains Vitamin A and C</li>
                    <li>Potent source of antioxidants</li>
                    <li>Rich source of iron, potassium, and manganese</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Relieves indigestion</li>
                    <li>Decreases breastfeeding pain</li>
                    <li>Improves respiratory problems</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={Peppermint} alt="img" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img src={Parsley} alt="img" className="img-fluid p-3" />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Parsley
              </h2>
              <p className="text-center ">
                The world's most popular herb, Parsley has a strong, fresh and
                clean taste that adds vibrancy and flavour to dishes.
              </p>

              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Packed with vitamins C, B12, K, and A</li>
                    <li>
                      Rich in antioxidants like flavonoids and carotenoids
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Improves digestion</li>
                    <li>Supports bone health</li>
                    <li>Reduces spasms</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Rosemary
              </h2>
              <p className="text-center ">
                Make it merry with Rosemary!
                <br />
                Rosemary is one of the most fragrant herbs which can be used as
                seasoning in salads, pizzas and as an addition to cocktail
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Packed with antioxidants</li>
                    <li>Good source of vitamin A, B6 and beta-carotene</li>
                    <li>
                      Contains essential minerals like Iron, potassium and
                      calcium
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Improves digestion</li>
                    <li>Improves blood circulation</li>
                    <li>Prevents brain ageing</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={Rosemary} alt="img" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img src={Sage} alt="img" className="img-fluid p-3" />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Sage
              </h2>
              <p className="text-center ">
                An aromatic feeling of warmth in your dishes- Sage!
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Contains vitamin A, C and K</li>
                    <li>Loaded with antioxidants</li>
                    <li>
                      Contains Chlorogenic acid, caffeic acid, rosmarinic acid,
                      ellagic acid and rutin
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Eases menopause symptoms</li>
                    <li>Reduces blood sugar levels</li>
                    <li>Supports brain health</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Thyme
              </h2>
              <p className="text-center ">
                Thyme is an all-purpose herb which adds a wonderful and mild
                flavour to any dish.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Vitamin A and C enriched</li>
                    <li>Contains small amounts of Potassium</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Helps fight acne</li>
                    <li>Helps in treating cough</li>
                    <li>Prevents bacterial infections</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={Thyme} alt="img" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default FreshHerbs;
