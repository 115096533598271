import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import nvphImg from "../../Assets/gallery/blogNVPHA.png";
import fanbadaFrame from "../../Assets/gallery/1.png";
import NvphFlatbed from "../../Assets/gallery/nflatbed.png";
import climateImg from "../../Assets/gallery/climatePondicherry.png";
import blog1Img from "../../Assets/gallery/blog1Img.png";
import Blog6Img from "../../Assets/Blog6Img.jpeg";
import Commercial_Hydroponic_Img from "../../Assets/gallery/Commercial_Hydroponic_Img.jpeg";
import polyhouse_bog_img from "../../Assets/polyhouse_blog_img.png";
import Hydroponics_types_img from "../../Assets/Hydroponics_types_img.png";
import Top10_hydroponic_crops_img from "../../Assets/Top10_hydroponic_crops_img.png";
import "./blog.css";

function Blog() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      mirror: true,
      once: false,
      offset: 120, // Adjust this offset to control when the animation triggers
    });
    AOS.refresh(); // Refresh AOS to ensure all elements are registered
  }, []);

  return (
    <Container fluid>
      {/* Header Row */}
      <Row className="headingimg">
        <Col className="m-5">
          <h2 className="text-white m-4 mx-4 px-2 fw-bold text-center">
            Blogs
          </h2>
          <div className="text-white m-4 fw-bold text-center">
            <a href="/" className="text-white m-3 text-decoration-none">
              Home
            </a>
            <i className="fa-solid fa-circle-arrow-right"></i>
            <a href="#" className="text-white text-decoration-none m-3">
              Blogs
            </a>
          </div>
        </Col>
      </Row>

      {/* Blog Cards Row 1 */}
      <Row className="p-3 bg-dark">
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="100">
          <div className="card h-100 border border-2">
            <img
              src={blog1Img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Blog 1 Image"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                How to Reduce Future Maintenance in Polyhouse Farming
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link to="/HowToReduceFutureMaintenanceInPolyhouseFarming" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="350">
          <div className="card h-100">
            <img
              src={nvphImg}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Blog 2 Image"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                NVPH A Frame
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link to="/NVPHBlog" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="550">
          <div className="card h-100">
            <img
              src={fanbadaFrame}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Blog 3 Image"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
              FANPAD A Frame
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link to="/FanpadAFrame_Blog" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>

      {/* Blog Cards Row 2 */}
      <Row className="p-3 bg-dark">
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="750">
          <div className="card h-100">
            <img
              src={NvphFlatbed}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Blog 4 Image"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
              NVPH Flatbed Hydroponics
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link to="/NVPHFlatbed_Blog" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="850">
          <div className="card h-100">
            <img
              src={climateImg}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Blog 5 Image"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
              Climate Overview for Hydroponic Farming in Pondicherry,
                India
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link to="/ClimateOverview_Blog" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="950">
          <div className="card h-100">
            <img
              src={Blog6Img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Blog 5 Image"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Hydroponic Farming in India:Revolutionizing Agriculture with Royal Green House
                </h2>
            </div>
            <div className="card-footer text-center">
              <Link to="/Hydroponic_Farming_In_India" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center p-3 bg-dark">
      <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="1000">
          <div className="card h-100">
            <img
              src={Commercial_Hydroponic_Img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Commercial Hydroponic farm setup in India "
            />
            <div className="card-body">
              <h1 className="card-title text-decoration-underline">
                Commercial Hydroponic Farming:</h1> <h2>A New Frontier in Agriculture
                </h2>
            </div>
            <div className="card-footer text-center">
              <Link to="/Commercial_Hydroponic_Farming" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="1000">
          <div className="card h-100">
            <img
              src={polyhouse_bog_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Commercial Hydroponic farm setup in India "
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
              Polyhouse Farming:Benefits and Setup with RGCPL Expertise
                </h2>
            </div>
            <div className="card-footer text-center">
              <Link to="/Polyhouse_Farming_Benefits_and_Setup" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="1000">
          <div className="card h-100">
            <img
              src={Hydroponics_types_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Commercial Hydroponic farm setup in India "
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
              Types of Hydroponic Systems</h2>
                
            </div>
            <div className="card-footer text-center">
              <Link to="/Types_of_Hydroponics_Systems" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center p-3 bg-dark">
      <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="1000">
          <div className="card h-100">
            <img
              src={Top10_hydroponic_crops_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Commercial Hydroponic farm setup in India "
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
              Top 10 Hydroponic Crops for India</h2>
                
            </div>
            <div className="card-footer text-center">
              <Link to="/Top10_hydroponic_crops_for_india" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Blog;
