import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.png";
import CardImg2 from "../../Assets/step2.png";
import CardImg3 from "../../Assets/step3.png";
import CardImg4 from "../../Assets/step4.png";
import Enquiry from "../../Components/Enquiry";
import Accordion from "react-bootstrap/Accordion";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import Shadenet from "../../Assets/gallery/Shadenet.png";
import Fogger from "../../Assets/Fogger.png";
import "../../App.css";

function Flat_Shade_Net_House() {
  return (
    <>
      <Container fluid>
        <Row className="headingimgtunnel">
          <Col className="m-5">
            <h1 className="text-dark m-4 mx-4 px-2 fw-bold text-center">
              Flat Shade Net House
            </h1>
            <div className="text-dark m-4 fw-bold text-center">
              <a href="/" className=" text-dark m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-dark text-decoration-none m-3">
                Flat Shade Net House
              </a>
            </div>
          </Col>
        </Row>

        <Row className="m-5 ps-5">
          <p className="fw-bold display-1 text-warning text-center">
            Flat Shade Net House
          </p>
        </Row>

        <Row>
          <Col className="m-5 mb-2">
            <h6 className="text-green m-2">Flat Shade Net House</h6>
            <h2 className="m-2">Flat Shade Net House</h2>
            {/* <p className='text-muted m-2'>Naturally Ventilated Greenhouse with 10 % Top Ventilation
. 50% or 75% Black Shadenet will be provided above tunnel at 1.0 mtr distance.
Top shadenet is motorized operated.Most suitable for production of potted plants which requires high humidity.</p>
                        <p className='text-muted m-2'>
                           Royal Green House is a most recognised brand in the Indian Hydroponics Industry. We install and set up Hydroponics Project Development that allows you to avail the benefits of a completely automated farm with year round production.

                        </p> */}
            <Row>
              <Col lg={6}>
                <p className="text-muted m-2 text-justify">
                  A flat shade net house is a versatile agricultural structure
                  designed with a flat roof covered by UV-stabilized shade nets.
                  The frame is typically constructed from durable materials like
                  galvanized iron (GI) pipes or steel, ensuring long-term
                  stability and resistance to corrosion. This design is
                  particularly suitable for regions with minimal rainfall and
                  moderate wind conditions. The shade nets, available in various
                  percentages, filter sunlight to provide the optimal light
                  environment for different crops while protecting them from
                  excessive heat, pests, and environmental stress. The open
                  sides of the structure offer excellent natural ventilation,
                  helping to regulate temperature and humidity levels inside.
                  Flat shade net houses are also customizable in size and
                  design, making them adaptable for various farming operations,
                  whether small or large-scale. This structure helps create a
                  controlled environment that enhances crop growth and yield by
                  mitigating harsh external factors.
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img src={Shadenet} alt="vine" className="img-fluid rounded" />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="m-5">
          <Col >
          <img src={Fogger} alt="vine" className="img-fluid rounded" />
          </Col>
          <Col className="mx-5">
          <p>
            <h1>Foggers for Shade Net Houses</h1>

          At Royal Green House Construction Pvt Ltd (RGCPL), we provide advanced fogging systems designed to optimize climate control in shade net houses.

            <h3 className="m-2">Features</h3>
            <ul className="text-justify">
              <li className="text-muted m-2">
             <span className="fw-bold">Temperature Reduction:</span>  Our foggers lower internal temperatures by releasing a fine mist that cools the air through evaporation, creating a more favorable environment for plants.
              </li>
              <li className="text-muted m-2"><span className="fw-bold"Humidity> Control: </span> The addition of moisture helps maintain optimal humidity levels, reducing plant stress and supporting healthy growth.</li>
              <li className="text-muted m-2"><span className="fw-bold">Uniform Cooling:</span> The fine mist ensures even temperature distribution, preventing hot spots and maintaining a consistent climate throughout the shade net house.</li>
              <li className="text-muted m-2"><span className="fw-bold">Efficient Water Usage:</span> Our systems are designed to minimize water waste, delivering necessary moisture to plants while avoiding excess runoff.</li>
              <li className="text-muted m-2">
              <span className="fw-bold"> Growth:</span>Enhanced By effectively managing temperature and humidity, our foggers promote better plant health and increased yields.
              </li>
              
            </ul>
            </p>
          </Col>
        </Row>
        {/* <Row>
          <Col lg={12} className=" p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and A frame system now we can
                      grow 70 percent more food in the same area.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow anywhere you want
                    </Card.Title>
                    <Card.Text>
                      We can now grow our food anywhere with help of just water
                      and protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-hand-holding-droplet text-green text-center fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title className="text-center">Less water</Card.Title>
                    <Card.Text>
                      Now grow crops on 90% less water compared to field crops.
                      This farming is possible at any location even in water
                      scared zones.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-bolt text-green text-center fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      High value cash crops
                    </Card.Title>
                    <Card.Text>
                      Not only our daily crops but we can grow super-crops or
                      very high value cash crops with controlled environment.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row> */}
        <Row>
          <Col sm={12}>
            <h6 className="text-green m-2 text-center">
              Our Process In Some Steps
            </h6>
            <h4 className="m-2 text-center fw-bold">
              Building Commercially Successful Hydroponic Farm
            </h4>
          </Col>
        </Row>
        <Row className="p-2 m-3">
          {/* second cards */}
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg1} alt="Step One"/>
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Site Feasibility
                </Card.Title>
                <Card.Text className=" text-white p-1">
                  We help you identify a site location to set up our hydroponic
                  project. We consider locality, levelling of land, examine
                  electricity and water also gather basic information of
                  surrounding that can help us test feasibility.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg2}  alt="Step Two"/>
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Ideal Farm Construction
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once financial feasibility is analysed we start working on the
                  Construction of the Project. Based on site feasibility and
                  market research we choose between NVPH or Fan-Pad system and
                  also hydroponic system between NFT or Dutch Bucket.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg3}  alt="Step Three" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Farm Management{" "}
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once construction is completed, we work on automating the farm
                  using IoT devices as well as integrating with farm management.
                  At this stage, we also start training your field team to make
                  sure we continuously grow high quality produce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg4}  alt="Step Four"/>
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Contract & Markerting Your Product
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once our field team is ready and the complete farm is
                  automated, Our team of agronomists, sales and planning starts
                  working on the contract and set a selling mechanism for your
                  farm, with a schedule of crops.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default Flat_Shade_Net_House;
