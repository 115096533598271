// src/components/TawkToChat.js
import { useEffect } from "react";

const TawkToChat = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src = "https://embed.tawk.to/66a8dd1532dca6db2cb76ef3/1i41pv7f3";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default TawkToChat;
