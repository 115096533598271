import React, { useEffect } from 'react';

const GoogleTranslate = () => {
  useEffect(() => {
    // Check if the Google Translate script is already present
    const existingScript = document.getElementById('google-translate-script');
    if (existingScript) return; // Skip if already loaded

    // Create and append the Google Translate script
    const script = document.createElement('script');
    script.id = 'google-translate-script';
    script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    document.body.appendChild(script);

    // Initialize Google Translate once script is loaded
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({
        pageLanguage: 'en',
        includedLanguages: 'en,hi,bn,ta,te,kn,ml,gu,mr,or,ar,fr,es', // List of desired languages
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
      }, 'google_translate_element');
    };

    return () => {
      // Clean up script when component unmounts
      const scriptToRemove = document.getElementById('google-translate-script');
      if (scriptToRemove) {
        document.body.removeChild(scriptToRemove);
      }
      delete window.googleTranslateElementInit; // Remove the global callback
    };
  }, []);

  return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
