import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";

import v1 from "../../Assets/hydroponic video/vid1.mp4";
import v2 from "../../Assets/hydroponic video/vid2.mp4";
import v4 from "../../Assets/hydroponic video/vid4.mp4";

function Videos() {
  var vid = [{ src: v1 }, { src: v2 }, { src: v4 }];
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Videos
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">Videos</a>
            </div>
          </Col>
        </Row>
        <Row>
          {vid.map((video, index) => {
            return (
              <Col key={index}>
                <div className="text-center">
                  <iframe
                    src={video.src}
                    height="300"
                    title="Vimeo video"
                    allowFullScreen
                    className="rounded-4 p-1"
                  ></iframe>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
}

export default Videos;
