import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import style from "./changesitedata.module.css";
// import Base64 from "../Utilities/Base64";
import Card from "react-bootstrap/Card";

import { useNavigate } from "react-router-dom";

function Dashboard(props) {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isLogin == false) {
      navigate("/login", { replace: true });
    }
  }, [props.isLogin]);

  const [galleryData, setGalleryData] = useState();
  useEffect(() => {
    setGalleryData(props.galleryData);
  }, [props.galleryData]);

  //  for review
  const [reviewData, setReviewData] = useState([]);
  useEffect(() => {
    setReviewData(props.reviewData);
  }, [props.reviewData]);

  async function Base64(file) {
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }

  //  for review

  const addReviewPhoto = () => {
    const arr = [...reviewData];
    arr.push(arr[arr.length - 1]);
    arr[arr.length - 1] = { ...arr[arr.length - 1], add: true, delete: false };
    setReviewData(arr);
  };
  const handleChangeReview = async (e, ind) => {
    var fileValue = e.target.value;
    const arr = [...reviewData];
    arr[ind] = { ...arr[ind], [e.target.name]: fileValue };
    setReviewData(arr);
  };

  const addGalleryPhoto = () => {
    const arr = [...galleryData];
    arr.push(arr[arr.length - 1]);
    arr[arr.length - 1] = { ...arr[arr.length - 1], add: true, delete: false };
    setGalleryData(arr);
  };
  const handleChangeGallery = async (e, ind) => {
    var fileValue = await Base64(e.target.files[0]).then((x) => x);

    const arr = [...galleryData];
    arr[ind] = { ...arr[ind], [e.target.name]: fileValue };
    setGalleryData(arr);
  };

  const handleSubmit = (event, data, formName, index, del) => {
    event.preventDefault();
    data.formName = formName;
    if (del) {
      data.delete = true;
    }
    async function sendData() {
      let tempSendData = await fetch("//royalgreenhouse.co.in/crud.php", {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((response) => response.text())
        .then((json) => json)
        .catch((error) => {
          return "error";
        });
      if (tempSendData === "success") {
        if (data.formName === "gallery") {
          const arr = [...galleryData];
          arr[index] = { ...arr[index], show: true };
          if (arr[index].delete) {
            arr.splice(index, 1);
          }
          setGalleryData(arr);
        }
        //  for review
        else if (data.formName === "review") {
          const arr = [...reviewData];
          arr[index] = { ...arr[index], show: true };
          if (arr[index].delete) {
            arr.splice(index, 1);
          }
          setReviewData(arr);
        }
      } else {
        console.log("php error");
      }
    }
    sendData();
  };
  return (
    <div className="">
      <Tabs
        defaultActiveKey="gallery"
        id="uncontrolled-tab-example"
        className="mb-3 pt-3"
      >
        <Tab eventKey="gallery" title="Gallery">
          <div className={style.container}>
            {galleryData &&
              galleryData.map((elem, ind) => {
                return (
                  <form
                    key={ind}
                    className={style.contact}
                    action="#"
                    encType="multipart/form-data"
                    method="post"
                    onSubmit={(e) => handleSubmit(e, elem, "gallery", ind)}
                  >
                    <h3>Gallery Data</h3>
                    <h4>Change gallery data</h4>
                    <span
                      type="button"
                      onClick={(e) =>
                        window.confirm("Are you sure you wamt to delete this?")
                          ? handleSubmit(e, elem, "gallery", ind, true)
                          : ""
                      }
                      className="my-2 p-2 rounded-3 text-light bg-danger"
                    >
                      Delete Image
                    </span>
                    <fieldset>
                      <span></span>

                      <img className="img-fluid" src={elem.image || ""} />
                    </fieldset>
                    <fieldset>
                      <span>Change Image</span>
                      <input
                        name="image"
                        type="file"
                        onChange={(e) => handleChangeGallery(e, ind)}
                        multiple
                      />
                    </fieldset>

                    <fieldset>
                      <button name="submit" type="submit">
                        Send
                      </button>
                    </fieldset>
                    {elem.show === true && (
                      <div className={style.success}>
                        <i className="fa fa-check"></i>
                        Form Save successfully !
                      </div>
                    )}
                  </form>
                );
              })}

            <div onClick={addGalleryPhoto} className={style.addBtn}>
              ADD
            </div>
          </div>
        </Tab>

        {/* for review */}

        <Tab eventKey="review" title="Review">
          <div className={style.container}>
            {reviewData &&
              reviewData.map((elem, ind) => {
                return (
                  <form
                    key={ind}
                    className={style.contact}
                    action="#"
                    encType="multipart/form-data"
                    method="post"
                    onSubmit={(e) => handleSubmit(e, elem, "review", ind)}
                  >
                    <h3>Review Data</h3>
                    <h4>Change review data</h4>
                    <span
                      type="button"
                      onClick={(e) =>
                        window.confirm("Are you sure you wamt to delete this?")
                          ? handleSubmit(e, elem, "review", ind, true)
                          : ""
                      }
                      className="my-2 p-2 rounded-3 text-light bg-danger"
                    >
                      Delete review
                    </span>
                    <fieldset>
                      <span></span>
                      <p>{elem.review || ""}</p>
                    </fieldset>
                    <fieldset>
                      <span>Change Review</span>
                      <input
                        name="review"
                        type="text"
                        onChange={(e) => handleChangeReview(e, ind)}
                        multiple
                      />
                    </fieldset>

                    <fieldset>
                      <button name="submit" type="submit">
                        Send
                      </button>
                    </fieldset>
                    {elem.show === true && (
                      <div className={style.success}>
                        <i className="fa fa-check"></i>
                        Form Save successfully !
                      </div>
                    )}
                  </form>
                );
              })}

            <div onClick={addReviewPhoto} className={style.addBtn}>
              ADD
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}
export default Dashboard;
