import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import gp1 from "../../Assets/gp1.png";
import gp2 from "../../Assets/gp2.png";
import gp3 from "../../Assets/gp3.png";
import gp4 from "../../Assets/gp9.png";
import gp5 from "../../Assets/gp5.png";
import gp6 from "../../Assets/gp6.png";
import gp7 from "../../Assets/gp7.png";
import gp8 from "../../Assets/gp8.png";

function GalleryofProjects() {
  return (
    <Container className="my-2 py-2">
      <Row>
        <Col sm={12} className="mt-4 pt-4 ">
          <h5 className="text-green m-4 text-center fw-bold">
            32 Projects in last one year
          </h5>
          <h2 className="text-center m-4 fw-bold">
            Check our gallery of projects
          </h2>
        </Col>
      </Row>

      <Row className="p-2">
        <Col lg={3} className="p-2 ">
          <a href="#">
            <img src={gp1} className="img-fluid rounded imghover" alt="img9" />
          </a>
        </Col>

        <Col lg={3} className="p-2">
          <a href="#">
            <img src={gp2} className="img-fluid rounded imghover" alt="img10" />
          </a>
        </Col>
        <Col lg={3} className="p-2">
          <a href="#">
            <img src={gp3} className="img-fluid rounded imghover" alt="img11" />
          </a>
        </Col>
        <Col lg={3} className="p-2">
          <a href="#">
            <img src={gp4} className="img-fluid rounded imghover" alt="img12" />
          </a>
        </Col>
      </Row>
      <Row className="p-2">
        <Col lg={3} className="p-2">
          <a href="#">
            <img src={gp5} className="img-fluid rounded imghover" alt="img13" />
          </a>
        </Col>
        <Col lg={3} className="p-2">
          <a href="#">
            <img src={gp6} className="img-fluid rounded imghover" alt="img14" />
          </a>
        </Col>
        <Col lg={3} className="p-2">
          <a href="#">
            <img src={gp7} className="img-fluid rounded imghover" alt="img15" />
          </a>
        </Col>
        <Col lg={3} className="p-2">
          <a href="#">
            <img src={gp8} className="img-fluid rounded imghover" alt="img16" />
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default GalleryofProjects;
